import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCart } from "../contexts/PanierContext"; // Adjust the path as necessary

const ValidationRoute = () => {
  const { Cart } = useCart();

  // Check if the cart is empty
  const isCartEmpty = Cart.length === 0;

  return isCartEmpty ? <Navigate to="/" /> : <Outlet />;
};

export default ValidationRoute;
