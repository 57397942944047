import { motion } from "framer-motion";
import { FaPlus } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaHouseChimney } from "react-icons/fa6";
import { TbRosetteDiscount } from "react-icons/tb";
import { IoCloseOutline } from "react-icons/io5";
import { CiShoppingCart } from "react-icons/ci";
import { MdModeEdit } from "react-icons/md";
import { FaMinus } from "react-icons/fa6";

export default function SideBar({isOpen,setIsOpen}) {
  return (
    <div className="flex">
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: isOpen ? 0 : '-100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className={`bg-gray-800  z-50 text-white w-64 p-4 space-y-4 fixed lg:relative lg:translate-x-0 top-0 h-full lg:h-auto ${isOpen ? "block lg:hidden" : "hidden"}`}
      >
               <div className="flex items-center justify-end">
                  <IoCloseOutline className="text-[17px] text-white" onClick={() => setIsOpen(false)}/>
               </div>
               <div className="flex items-center justify-center pt-6">
                  <img src="/logo.png" alt="logo" className="w-[50px] h-[50px]"/>
               </div>
               <ul className="flex flex-col w-full items-center pt-6">
                   <NavLink to="/admin/admincompteAdmin.123/" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[14px] flex py-4 items-center gap-2">
                       <FaHouseChimney />
                       Admin Acceuil
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/commandes" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[14px] flex py-4 items-center gap-2">
                       <CiShoppingCart/>
                       Commandes
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/addproduct" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[14px] flex py-4 items-center gap-2">
                       <FaPlus />
                       Ajouter Un Produit
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/removeproduct" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[14px] flex py-4 items-center gap-2">
                       <FaMinus />
                       Supprimer Un Produit
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/modifyproduct" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[14px] flex py-4 items-center gap-2">
                       <MdModeEdit/>
                       Modifier Un Produit
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/soldes" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[14px] flex py-4 items-center gap-2">
                       <TbRosetteDiscount/>
                       Soldes
                   </NavLink>
               </ul>
      </motion.div>
      <section className="min-h-screen sticky w-[300px] hidden lg:flex bg-neutral-800">
         <div className="w-full h-full py-3">
           <div className="flex flex-col h-full w-full">
               <div className="flex items-center justify-center pt-6">
                  <img src="/logo.png" alt="logo" className="w-[100px] h-[100px]"/>
               </div>
               <ul className="flex flex-col w-full items-center pt-6">
                   <NavLink to="/admin/admincompteAdmin.123/" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[17px] flex py-4 items-center gap-2">
                       <FaHouseChimney />
                       Admin Acceuil
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/commandes" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[17px] flex py-4 items-center gap-2">
                       <CiShoppingCart/>
                       Commandes
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/addproduct" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[17px] flex py-4 items-center gap-2">
                       <FaPlus />
                       Ajouter Un Produit
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/removeproduct" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[17px] flex py-4 items-center gap-2">
                       <FaMinus />
                       Supprimer Un Produit
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/modifyproduct" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[17px] flex py-4 items-center gap-2">
                       <MdModeEdit/>
                       Modifier Un Produit
                   </NavLink>
                   <NavLink to="/admin/admincompteAdmin.123/soldes" activeclassname="Adminctive" className="hover:bg-neutral-600 transition text-white justify-start px-6 Linky w-full text-[17px] flex py-4 items-center gap-2">
                       <TbRosetteDiscount/>
                       Soldes
                   </NavLink>
               </ul>
           </div>
         </div>
      </section>
    </div>
  );
}
