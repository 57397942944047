import React, { useState } from "react";
import { FaHouse } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { Toaster, toast } from 'sonner'
import { IoMdAlert } from "react-icons/io";
import { HiHome } from "react-icons/hi";
import { Breadcrumb } from "flowbite-react";
import axios from 'axios';

const Contact = () => {
  const [form, setForm] = useState({
    Nom: "",
    Email: "",
    Téléphone: "",
    Message: "",
  });
  const [Error, setError] = useState("");
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true)
    // Basic validation
    if (!form.Nom.trim()) {
      setError("Veuillez entrer votre nom.");
      setSubmitLoading(false)
      return;
    }
    if (!form.Email.trim()) {
      setError("Veuillez entrer votre adresse email.");
      setSubmitLoading(false)
      return;
    }
    if (!form.Téléphone.trim()) {
      setError("Veuillez entrer votre numéro de téléphone.");
      setSubmitLoading(false)
      return;
    }
    if (!form.Message.trim()) {
      setError("Veuillez entrer votre message.");
      setSubmitLoading(false)
      return;
    }
  
    // If all fields are valid, proceed with form submission
    setError("")
    try {
      // Make an Axios POST request to the server
      const response = await axios.post('http://localhost:5000/addContact', {
          nom: form.Nom,
          email: form.Email,
          téléphone: form.Téléphone,
          message: form.Message
      });

      // Handle the response if needed
      console.log("Server response: ", response.data);
      if(response.data.Status === "Success"){
        setSubmitLoading(false)
        toast.success('Le Message a été Envoyé Avec Succès')
        return
      }
  } catch (error) {
      // Handle the error
      console.error("Error submitting the form: ", error);
      setError("Une erreur est survenue lors de l'envoi du message.");
  }
  };

  return (
    <>
      <section className="relative z-10 overflow-hidden bg-gray-100 py-20 dark:bg-dark lg:pb-[120px] lg:pt-[60px]">
        <Toaster richColors position="top-center"/>
        <div className="flex flex-col">
          <h1 className="mb-2 sm:mx-[120px] mx-4 sm:text-[60px] text-[40px] font-bold Linky uppercase">
            Contact
          </h1>
          <Breadcrumb aria-label="Panier Breadcrumb" className="mb-10 sm:mx-[120px] mx-2">
              <Breadcrumb.Item href="/" icon={HiHome} className="flex gap-2 text-[0px]">
                <p className="px-1">Home</p>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/Contact" icon={FaPhoneAlt}>
                <p className="px-1">Contact</p>
              </Breadcrumb.Item>
            </Breadcrumb>
          <div className="-mx-4 flex flex-wrap lg:justify-between mt-[40px]">
            <div className="w-full bg-blue-700 px-[60px] py-12">
              <div className="mb-12 max-w-full lg:mb-0">
                <h2 className="mb-6 sm:text-[32px] text-[20px] text-center font-bold uppercase text-white">
                  CONTACTEZ-NOUS
                </h2>
                <p className="mb-9 text-white sm:text-[13px] text-[10px] leading-relaxed text-body-color dark:text-dark-6 text-center">
                  Nous sommes là pour vous aider à atteindre vos objectifs de
                  santé et de bien-être. Si vous avez des questions sur nos
                  produits ou besoin de conseils personnalisés, n'hésitez pas à
                  nous contacter. Notre équipe d'experts est à votre disposition
                  pour vous offrir le meilleur service. Ensemble, atteignons vos
                  objectifs de forme et de vitalité !
                </p>
                <div className="flex w-full minlg:px-12 md:items-start items-center md:gap-0 gap-6 justify-center md:flex-row flex-col">
                  <div className="flex w-full md:max-w-[30%] justify-center items-center text-white">
                    <div className="flex xmd:w-[50%] xs:w-[80%] w-full md:w-full">
                       <div className=" xmd:pb-[20px] flex w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                         <FaHouse />
                       </div>
                       <div className="w-full">
                         <h4 className="mb-1 text-[13px] xs:text-[15px] flex gap-2 items-center font-bold text-dark dark:text-white">
                           Localisation
                         </h4>
                         <p className="text-[10px] xs:text-[13px] text-body-color dark:text-dark-6">
                           Houmet Souk Djerba <br /> Mednine Tunisie
                         </p>
                       </div>
                    </div>
                  </div>
                  <div className="flex w-full md:max-w-[30%] justify-center items-center text-white">
                     <div className="flex xmd:w-[50%] xs:w-[80%] w-full md:w-full">
                        <div className=" xmd:pb-[20px] flex w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                          <FaPhoneAlt />
                        </div>
                        <div className="w-full">
                          <h4 className="mb-1 text-[13px] xs:text-[15px] font-bold text-dark dark:text-white">
                            Numéro De Téléphone
                          </h4>
                          <p className="text-[10px] xs:text-[13px] text-body-color dark:text-dark-6">
                            (+216) 57 044 501 / 57 044 510
                          </p>
                        </div>
                     </div>
                  </div>
                  <div className="flex w-full md:max-w-[30%] justify-center items-center text-white">
                    <div className="flex md:w-full xmd:w-[50%] xs:w-[80%] w-full">
                        <div className=" xmd:pb-[20px] flex w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                          <AiOutlineMail />
                        </div>
                        <div className="w-full">
                          <h4 className="mb-1 text-[13px] xs:text-[15px] font-bold text-dark dark:text-white">
                            Adresse Email
                          </h4>
                          <p className="text-[10px] xs:text-[13px] text-body-color dark:text-dark-6">
                            atefdhrifi20@gmail.com
                          </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-12 sm:px-6 px-1 flex minlg:flex-row flex-col">
            <div className="minlg:w-1/2 w-full minlg:h-[500px] sm:h-[400px] h-[300px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d207.01712548493276!2d10.855597251971625!3d33.882596304469246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13aaa50043ee88d7%3A0xc756059b6c443a1b!2sGlobal%20Prot%C3%A9ine%20Djerba!5e0!3m2!1sen!2stn!4v1723980476975!5m2!1sen!2stn"
                style={{ border: "0" }}
                title="map"
                allowFullScreen=""
                loading="lazy"
                className="w-full h-full"
              ></iframe>
            </div>
            <div className="sm:px-4 minlg:w-1/2 w-full">
              <div className="relative rounded-lg p-8 dark:bg-dark-2 sm:p-12">
                <form onSubmit={handleSubmit}>
                  {Error.length !== 0 && ( <div className="bg-red-400 text-white flex gap-2 items-center justify-center w-full py-2 rounded-xl mb-4"><IoMdAlert />{Error}</div>)}
                  <ContactInputBox
                    type="text"
                    name="Nom"
                    placeholder="Votre Nom"
                    value={form.Nom}
                    onChange={handleChange}
                  />
                  <ContactInputBox
                    type="email"
                    name="Email"
                    placeholder="Votre Email"
                    value={form.Email}
                    onChange={handleChange}
                  />
                  <ContactInputBox
                    type="tel"
                    name="Téléphone"
                    placeholder="Votre Téléphone"
                    value={form.Téléphone}
                    onChange={handleChange}
                  />
                  <ContactTextArea
                    row="6"
                    placeholder="Votre Message"
                    name="Message"
                    value={form.Message}
                    onChange={handleChange}
                  />
                  <div>
                    <button
                      type={SubmitLoading ? "" : "Submit"}
                      className={`w-full bg-blue-400 border-blue-400 flex items-center justify-center hover:bg-transparent hover:text-blue-400 rounded border border-primary bg-primary p-3 text-white transition`}
                    >
                      {SubmitLoading ? (
                      <div role="status">
                          <svg aria-hidden="true" className="w-8 h-8 text-gray-200 text-center animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                          </svg>
                          <span className="sr-only">Loading...</span>
                      </div>
                      ) : "Send Message"}
                    </button>
                  </div>
                </form>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

const ContactTextArea = ({ row, placeholder, name, value, onChange }) => {
  return (
    <div className="mb-6">
      <textarea
        rows={row}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full border-blue-400 placeholder-blue-400 text-blue-400  bg-transparent resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none"
      />
    </div>
  );
};

const ContactInputBox = ({ type, placeholder, name, value, onChange }) => {
  return (
    <div className="mb-6">
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full border-blue-400 placeholder-blue-400 text-blue-400 rounded border border-stroke px-[14px] py-3 bg-transparent text-base outline-none focus:border-primary"
      />
    </div>
  );
};
