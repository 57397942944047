import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useMediaQuery } from 'react-responsive';
import { IoArrowForwardSharp } from "react-icons/io5";
import { IoArrowBackSharp } from "react-icons/io5";

const useBreakpoints = () => {
    const xl = useMediaQuery({ minWidth: 1024 });
    const xxmd = useMediaQuery({ minWidth: 800, maxWidth: 1023 });
    const md = useMediaQuery({ minWidth: 500, maxWidth: 799 });
    const sm = useMediaQuery({ minWidth: 300, maxWidth: 499 });
    const xs = useMediaQuery({ maxWidth: 299 });

    const getSlidesToShow = () => {
        if (xl) return 5;
        if (xxmd) return 4;
        if (md) return 3;
        if (sm) return 2;
        if (xs) return 1;
        return 5; // Default to 5 if no condition matches
    };

    return getSlidesToShow;
};

const products = [
    { id: 1, imgSrc: "/Brands/imageOne.png" },
    { id: 2, imgSrc: "/Brands/imageTwo.png" },
    { id: 3, imgSrc: "/Brands/imageThree.png" },
    { id: 4, imgSrc: "/Brands/imageFour.png" },
    { id: 5, imgSrc: "/Brands/imageFive.png" },
    { id: 6, imgSrc: "/Brands/imageSix.png" },
    { id: 7, imgSrc: "/Brands/imageSeven.png" },
    { id: 8, imgSrc: "/Brands/imageEight.png" },
    { id: 9, imgSrc: "/Brands/imageNine.png" },
    { id: 10, imgSrc: "/Brands/imageTen.png" },
    { id: 11, imgSrc: "/Brands/imageEleven.png"},
];

const CardSlider = () => {
    const slidesToShow = useBreakpoints();

    return (
        <div className="w-full sm:px-0 px-6 relative">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={slidesToShow()}
                spaceBetween={10}
                loop={true}
                navigation={{
                    nextEl: '.swiper-button-next-custom',
                    prevEl: '.swiper-button-prev-custom',
                }}
            >
                {products.map(product => (
                    <SwiperSlide key={product.id} className="bg-white sm:w-[197px] w-[100px] h-[100px] sm:h-[126px] rounded-xl">
                        <div className="p-2 w-full h-full">
                            <div className="h-full w-full flex items-center justify-center">
                                <img src={product.imgSrc} alt="product" className="w-full h-full" />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {/* Custom navigation buttons */}
            <div className="swiper-button-prev-custom absolute top-1/2 left-0 transform -translate-y-1/2 sm:-translate-x-2/3 z-10 cursor-pointer">
                <span className="inline-block p-2 sm:text-[20px] text-[13px] text-white bg-gray-800  rounded-full"><IoArrowBackSharp /></span>
            </div>
            <div className="swiper-button-next-custom absolute top-1/2 right-0 transform -translate-y-1/2 sm:translate-x-2/3 z-10 cursor-pointer">
                <span className="inline-block p-2 sm:text-[20px] text-[13px] text-white bg-gray-800 rounded-full"><IoArrowForwardSharp /></span>
            </div>
        </div>
    );
};

export default CardSlider;
