import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import UpBar from "./components/UpBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Panier from "./pages/Panier";
import Boutique from "./pages/Boutique";
import Contact from "./pages/Contact";
import SideBar from './components/SideBar';
import AdminHomePage from "./pages/Admin/AdminHomePage"; // Rename to avoid conflict with HomePage
import AddProduct from "./pages/Admin/AddProduct";
import RemoveProduct from "./pages/Admin/RemoveProduct";
import ModifyProduct from "./pages/Admin/ModifyProduct";
import Soldes from "./pages/Admin/Soldes";
import Commandes from "./pages/Admin/Commandes";
import axios from 'axios';
import AdminNavbar from "./components/AdminNavbar";
import Validation from "./pages/Validation";
import CartContextProvider from "./contexts/PanierContext";
import { DisclosureProvider } from "./contexts/DisclosureContext";
import ProductInfo from "./pages/ProductInfo";
import ValidationRoute from "./utils/ValidationRoute";
import Calories from "./pages/Calories";
import Brands from "./pages/Brands";
import NotFoundPage from "./pages/NotFoundPage";
import SearchResults from "./pages/SearchResults";
import { Toaster } from "sonner";
import { Helmet, HelmetProvider } from "react-helmet-async"; // Import Helmet and HelmetProvider
import Loading from "./components/Loading";
import { useEffect, useState } from "react";
import ScrollToTop from "./contexts/ScrollToTop";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [Produits, setProduits] = useState([]);
  const [EnSoldeProduits, setEnSoldeProduits] = useState([]);
  
  useEffect(() => {
    const fetchProduits = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchProducts');
        if (response.data.Status === "Success") {
          setProduits(response.data.Data);
          setEnSoldeProduits(response.data.EnSoldeProduits)
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchProduits();
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  
  return (
    <CartContextProvider>
      <DisclosureProvider>
        <HelmetProvider>
          <BrowserRouter>
            <Toaster richColors position="top-center" />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={
                <>
                  <Helmet>
                    <title>Globale Proteine - Home</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <HomePage EnSoldeProduits={EnSoldeProduits} setEnSoldeProduits={setEnSoldeProduits} />
                  <Footer />
                </>
              } />
              <Route path="/Panier" element={
                <>
                  <Helmet>
                    <title>Your Cart - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <Panier />
                  <Footer />
                </>
              } />
              <Route element={<ValidationRoute />}>
                <Route path="/Validation" element={
                  <>
                    <Helmet>
                      <title>Order Validation - Globale Proteine</title>
                    </Helmet>
                    <UpBar />
                    <Navbar />
                    <Validation />
                    <Footer />
                  </>
                } />
              </Route>
              <Route path="/Boutique" element={
                <>
                  <Helmet>
                    <title>Shop - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <Boutique/>
                  <Footer />
                </>
              } />
              <Route path="/Contact" element={
                <>
                  <Helmet>
                    <title>Contact Us - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <Contact />
                  <Footer />
                </>
              } />
              <Route path="/calories" element={
                <>
                  <Helmet>
                    <title>Calories Calculator - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <Calories />
                  <Footer />
                </>
              } />
              <Route path="/produit/:title" element={
                <>
                  <Helmet>
                    <title>Product Details - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <ProductInfo />
                  <Footer />
                </>
              } />
              <Route path="/brands" element={
                <>
                  <Helmet>
                    <title>Our Brands - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <Brands />
                  <Footer />
                </>
              } />
              <Route path="/search/:query" element={
                <>
                  <Helmet>
                    <title>Search Results - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <SearchResults />
                  <Footer />
                </>
              } />
              <Route path="*" element={
                <>
                  <Helmet>
                    <title>Page Not Found - Globale Proteine</title>
                  </Helmet>
                  <UpBar />
                  <Navbar />
                  <NotFoundPage />
                  <Footer />
                </>
              } />
              <Route path="/admin/admincompteAdmin.123/" element={
                 <div className="flex relative">
                    <SideBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                    <div className="flex-grow">
                       <AdminNavbar setIsOpen={setIsOpen} />
                       <AdminHomePage Produits={Produits} />   
                    </div>
                 </div>
              } />
              <Route path="/admin/admincompteAdmin.123/addproduct" element={
                 <div className="flex relative">
                    <SideBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                    <div className="flex-grow">
                       <AdminNavbar setIsOpen={setIsOpen} />
                       <AddProduct />   
                    </div>
                 </div>
              } />
              <Route path="/admin/admincompteAdmin.123/removeproduct" element={
                 <div className="flex relative">
                    <SideBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                    <div className="flex-grow">
                       <AdminNavbar setIsOpen={setIsOpen} />
                       <RemoveProduct Produits={Produits} setProduits={setProduits} />   
                    </div>
                 </div>
              } />
              <Route path="/admin/admincompteAdmin.123/modifyproduct" element={
                  <div className="flex relative">
                     <SideBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                     <div className="flex-grow">
                        <AdminNavbar setIsOpen={setIsOpen} />
                        <ModifyProduct Produits={Produits} setProduits={setProduits} />   
                     </div>
                  </div>
              } />
              <Route path="/admin/admincompteAdmin.123/soldes" element={
                 <div className="flex relative">
                   <SideBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                   <div className="flex-grow">
                      <AdminNavbar setIsOpen={setIsOpen} />
                      <Soldes Produits={Produits} />   
                   </div>
                 </div>
              } />
              <Route path="/admin/admincompteAdmin.123/commandes" element={
                  <div className="flex relative">
                     <SideBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                     <div className="flex-grow">
                        <AdminNavbar setIsOpen={setIsOpen} />
                        <Commandes />   
                     </div>
                   </div>
               } />
            </Routes>
          </BrowserRouter>
        </HelmetProvider>
      </DisclosureProvider>
    </CartContextProvider>
  );
}

export default App;
