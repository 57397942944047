import React,{useState,useEffect} from 'react'
import { HiHome } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { motion } from 'framer-motion';
import Loading from '../components/Loading';
import { Breadcrumb } from "flowbite-react";
import axios from 'axios'
import { FaBagShopping } from "react-icons/fa6";
import { IoFilterSharp } from "react-icons/io5";
import { HiOutlineXMark } from "react-icons/hi2";
import { useCart } from '../contexts/PanierContext';
import { useDisclosure } from '../contexts/DisclosureContext';
import {Link} from 'react-router-dom'


const Brands = [
  "Optimum Nutrition",
  "Muscle Tech",
  "Biotech USA",
  "Nutrex",
  "Nutrend",
  "Ultimate Nutrition",
  "Applied Nutrition",
  "Olimp",
  "Animal",
  "Impact Nutrition",
  "Great Sport Nutrition",
  "Hx Nutrition",
  "Real Pharm"
];
const Categories = [
  "Mass Gainer",
  "Whey protéine",
  "Acides aminés",
  "Preworkout",
  "Creatine",
  "Multi vitamine",
  "Brûlures de graisse",
  "Accessoires",
  "Snack"
];


function Boutique() {
  const [accordionList, setaccordionList] = useState({
    categorie:false,
    brands:false,
    prix:false,
  });
  const { addCart } = useCart();
  const [isLoading, setisLoading] = useState(true);
  const [Produits, setProduits] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [showedProducts, setshowedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState(600);
  const [isFilter, setisFilter] = useState(false);
  const { setOpen } = useDisclosure();
  const handleAddToCart = (produit) => {
    const newProduit = {
      ...produit,        // Spread all properties from the produit object
      quantity: 1,
      id: Math.random().toString(36).substr(2, 9), // Generate a random ID     
      livraison:0
    };
  
    addCart(newProduit); // Add the newProduit to the cart
    setOpen(true);       // Optionally open a modal or give feedback
  };
  
  const toggleAccordion = (attribute) => {
    setaccordionList((prevState) => ({
      ...prevState,
      [attribute]: !prevState[attribute],
    }));
  };
  const handleCategoriesChange = (item) => {
    let updatedSelectedItems = [...selectedCategories]; // Create a new array from the current state
  
    if (updatedSelectedItems.includes(item)) {
      // If the item is already in the array, remove it
      updatedSelectedItems = updatedSelectedItems.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      // If the item is not in the array, add it
      updatedSelectedItems.push(item);
    }
  
    // Set the state with the updated array
    setSelectedCategories(updatedSelectedItems);
  };
  const handleBrandsChange = (item) => {
    let updatedSelectedItems = [...selectedBrands]; // Create a new array from the current state
  
    if (updatedSelectedItems.includes(item)) {
      // If the item is already in the array, remove it
      updatedSelectedItems = updatedSelectedItems.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      // If the item is not in the array, add it
      updatedSelectedItems.push(item);
    }
  
    // Set the state with the updated array
    setSelectedBrands(updatedSelectedItems);
  };
  const calcPour = (oldPrice, newPrice) => {
    const discount = oldPrice - newPrice;
    const discountPercentage = (discount / oldPrice) * 100;
  
    return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
  }
  useEffect(() => {
    let newProducts = [...Produits];
    let filteredProducts = newProducts;
  
    if (selectedCategories.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedCategories.includes(product.categorie)
      );
      console.log(filteredProducts,"insidecat")
    }
  
    if (selectedBrands.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedBrands.includes(product.brand)
      );
      console.log(filteredProducts,"insidebrand")
    }
  
    if(selectedPriceRange < 600){
      filteredProducts = filteredProducts.filter(
        (product) => product.prix <= selectedPriceRange
      );
      console.log(filteredProducts,"insideprice")
    }
  
    setshowedProducts(filteredProducts);
  }, [selectedPriceRange, selectedCategories, selectedBrands,Produits]);
  
  useEffect(() => {
   const fetchProduits = async () => {
     try {
       const response = await axios.get('/.netlify/functions/fetchProducts');
       if(response.data.Status === "Success") {
        setProduits(response.data.Data);
        setshowedProducts(response.data.Data)
        setisLoading(false);
       }
     } catch (err) {
       console.error(err)
     }
   };
   fetchProduits();
 }, []);
  const clearFilter = () => {
    setSelectedCategories([]);
    setSelectedBrands([])
    setSelectedPriceRange(600)
    setshowedProducts(Produits)
    const newAccordion = {
      categorie:false,
      brands:false,
      prix:false,
    }
    setaccordionList(newAccordion)
  }
  if(isLoading){
    return <Loading />
  }
  return (
    <div className="w-full min-h-screen pt-6 bg-gray-100">
        <div className="w-full h-full p-2">
           <h1 className="mb-2 sm:mx-[120px] mx-4 sm:text-[60px] text-[30px] font-bold Linky uppercase">Boutique</h1>
           <Breadcrumb aria-label="Panier Breadcrumb" className="mb-10 sm:mx-[120px] mx-2">
              <Breadcrumb.Item href="/" icon={HiHome} className="flex gap-2 text-[0px]">
                <p className="px-1">Home</p>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/Panier" icon={FaBagShopping}>
                <p className="px-1">Boutique</p>
              </Breadcrumb.Item>
            </Breadcrumb>
           <div className="minlg:hidden flex w-full items-center justify-start px-2">
             <span onClick={() => setisFilter(true)} className="text-white bg-blue-700 px-5 py-1 rounded-xl text-[13px] gap-2 flex items-center"><IoFilterSharp/>Open Filter</span>
           </div>
           <div className="w-full gap-6 flex minlg:px-10 xxmd:px-12 xmd:px-5 xs:px-5  pb-10">
                <div className="xlg:w-[25%] w-[35%] minlg:flex min-h-[500px] hidden sticky bg-white rounded-xl filter-component">
                    <div className="p-2 w-full h-full">
                        <div className="flex flex-col gap-4">
                             <div className="w-full flex items-center justify-between p-2">
                                 <h1 className="Linky text-[30px]">FILTER</h1>
                                 <div className="rounded-full p-2"><IoFilterSharp  className="text-[17px]"/></div>
                             </div>
                             <div className="flex flex-col w-full">
                                <div className="w-full py-6 flex items-center justify-center accordion trigger">
                                       <div className="flex items-center justify-between w-[70%] cursor-pointer" onClick={() => toggleAccordion("categorie")}>
                                           <div className="text-[20px] font-extrabold uppercase filter-link">catégories</div>
                                           <div className="rounded-full p-2 text-[17px]">{accordionList.categorie ? (<IoIosArrowUp className="text-[14px]"/>) : (<IoIosArrowDown className="text-[14px]"/>)}</div>
                                       </div>
                                </div>
                                {accordionList.categorie && (
                                  <div className="w-full px-6 h-[200px] overflow-auto scrollbar-custom accordion content">
                                  <div className="flex flex-col w-full">
                                      {Categories.map((name,index) => (
                                         <div key={index} className="flex items-center justify-start w-full space-x-2 py-3">
                                         <div className="min-w-[80%] flex gap-1">
                                             <div className="checkbox-wrapper-46">
                                                 <input className="inp-cbx" id={name} type="checkbox" checked={selectedCategories.includes(name)} onChange={() => handleCategoriesChange(name)}/>
                                                 <label className="cbx w-full" for={name}><span>
                                                   <svg width="12px" height="10px" viewbox="0 0 12 10">
                                                     <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                   </svg></span><span className="xlg:text-[15px] text-[13px] hiddenOne uppercase">{name}</span>
                                                 </label>
                                              </div>          
                                         </div>
                                        </div>
                                      ))}
                                  </div>
                                  </div>
                                )}
                                <div className="w-full py-6 flex items-center justify-center accordion trigger">
                                       <div className="flex items-center justify-between w-[70%] cursor-pointer" onClick={() => toggleAccordion("brands")}>
                                           <div className="text-[20px] font-extrabold uppercase filter-link">brands</div>
                                           <div className="rounded-full p-2 text-[17px]">{accordionList.brands ? (<IoIosArrowUp className="text-[14px]"/>) : (<IoIosArrowDown className="text-[14px]"/>)}</div>
                                       </div>
                                </div>
                                {accordionList.brands && (
                                  <div className="w-full px-6 h-[200px] overflow-auto scrollbar-custom accordion content">
                                  <div className="flex flex-col w-full">
                                      {Brands.map((name,index) => (
                                        <div key={index} className="flex items-center justify-start w-full space-x-2 py-3">
                                        <div className="min-w-[80%] flex gap-1">
                                            <div className="checkbox-wrapper-46">
                                                <input className="inp-cbx" id={name} type="checkbox" checked={selectedBrands.includes(name)} onChange={() => handleBrandsChange(name)}/> 
                                                <label className="cbx w-full" for={name}><span>
                                                  <svg width="12px" height="10px" viewbox="0 0 12 10">
                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                  </svg></span><span className="xlg:text-[15px] text-[13px] hiddenOne uppercase">{name}</span>
                                                </label>
                                             </div>          
                                        </div>
                                        </div>
                                      ))}
                                  </div>
                                  </div>
                                )}
                                <div className="w-full py-6 flex items-center justify-center accordion trigger">
                                       <div className="flex items-center justify-between w-[70%] cursor-pointer" onClick={() => toggleAccordion("prix")}>
                                           <div className="text-[20px] font-extrabold uppercase filter-link">prix</div>
                                           <div className="rounded-full p-2 text-[17px]">{accordionList.prix ? (<IoIosArrowUp className="text-[14px]"/>) : (<IoIosArrowDown className="text-[14px]"/>)}</div>
                                       </div>
                                </div>
                                {accordionList.prix && (
                                   <div className="w-full px-2 h-[200px] overflow-auto scrollbar-custom accordion content">
                                   <div className="flex flex-col  w-full">
                                           <div className="relative w-full items-center justify-center ">
                                               <input
                                                   type="range"
                                                   min="0"
                                                   max="600"
                                                   value={selectedPriceRange} // Bind the value to the state
                                                   id="lower"
                                                   onChange={(e) => setSelectedPriceRange(e.target.value)} // Update the state on slider change
                                               />
                                           </div>   
                                           <div className="flex items-center justify-start">
                                               <span className="text-[14px]">Prix:0TND -- {selectedPriceRange}TND</span>
                                           </div>      
                                           <div className="w-full flex items-center justify-start">
                                               <button className="w-full rounded-2xl bg-black text-white hover:bg-white py-1 mt-2 text-[15px] hover:text-black border border-black">CHERCHER</button>        
                                           </div>                                    
                                   </div>
                                   </div>
                                )}
                             </div>
                        </div>
                    </div>
                </div>
                <div className="minlg:w-[70%]  w-[100%] min-h-[500px] rounded-xl product component p-2">
                    <div className="flex flex-col w-full h-full xlg:px-0 minlg:px-6 ">
                         <div className="w-full flex items-center justify-between">
                             <span className="text-[18px] text-black">Sort By</span>
                             <button onClick={clearFilter} className="underline text-red-500 text-[16px] hover:text-red-700 cursor-pointer">Clear Filter</button>
                         </div>
                         {showedProducts.length !== 0 ? (
                            <div className="minlg:gap-4 gap-4 grid w-full xlg:grid-cols-3 mt-2 md:grid-cols-3 minlg:grid-cols-2 xmd:grid-cols-2 grid-cols-1">
                             {showedProducts.map((produit) => (
                              <div onMouseEnter={() => setIsHovered(true)}  onMouseLeave={() => setIsHovered(false)} className="h-[400px]  relative overflow-y-hidden bg-gray-200 rounded-xl shadow group cursor-pointer">
                                  {isHovered && ( <motion.div  initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }}  transition={{ duration: 0.2 }}className="text-white hover:bg-blue-500 group-hover:block absolute w-full left-0 h-[50px] bg-black bottom-0 hidden">
                                       <div onClick={()=>handleAddToCart(produit)} className="w-full h-full flex items-center justify-center">
                                          <p>AJOUTER AU PANIER</p>
                                       </div>
                                   </motion.div>)}
                                    {produit.ensolde && (<div className="absolute top-3 left-3 bg-red-500 diagonal-rectangle rounded-sm text-[12px] text-white p-1">{calcPour(produit.solde.oldprix,produit.prix)}% OFF</div>)}
                                    <Link to={`/produit/${produit.titre}`} className="flex flex-col">
                                       <div className="flex w-full h-[250px]">
                                         <img src={produit.image} alt="l'image de produit" className="rounded-t-xl w-full h-full"/>
                                       </div>
                                       <div className="flex flex-col w-full p-4 gap-[5px]">
                                             <h1 className="truncate-multiline hover:text-red-600 cursor-pointer uppercase">{produit.titre}</h1>
                                             <p className="hover:text-red-600 underline cursor-pointer text-[14px] text-gray-400 uppercase">{produit.categorie}</p>
                                             <div className="flex gap-2 items-center">
                                                {produit.ensolde && (
                                                   <p className="line-through text-gray-400 text-[14px]">{produit.solde.oldprix.toFixed(3)}TND</p>
                                                )}
                                                <p className="text-red-500 text-[17px]">{produit.prix.toFixed(3)}TND</p>
                                             </div>
                                       </div>
                                    </Link>
                               </div>      
                              ))} 
                              </div>
                         ) : (
                               <div className="w-full flex items-center justify-center">
                                    <p className="text-[17px]">Aucune Résultat</p>
                               </div>
                         )} 
                    </div>
                </div>
                {isFilter && (
                  <div className="w-screen minlg:hidden h-screen flex  bg-white z-50 absolute top-0 right-0">
                  <div className="p-2 w-full h-full">
                      <div className="flex flex-col gap-4">
                           <div className="w-full flex items-center justify-between p-2">
                               <h1 className="Linky text-[30px]">FILTER</h1>
                               <div onClick={() => setisFilter(false)} className="rounded-full p-2"><HiOutlineXMark className="text-[17px]"/></div>
                           </div>
                           <div className="flex flex-col w-full">
                              <div className="w-full py-6 flex items-center justify-center accordion trigger">
                                     <div className="flex items-center justify-between w-[70%] cursor-pointer" onClick={() => toggleAccordion("categorie")}>
                                         <div className="text-[20px] font-extrabold uppercase filter-link">catégories</div>
                                         <div className="rounded-full p-2 text-[17px]">{accordionList.categorie ? (<IoIosArrowUp className="text-[14px]"/>) : (<IoIosArrowDown className="text-[14px]"/>)}</div>
                                     </div>
                              </div>
                              {accordionList.categorie && (
                                <div className="w-full px-6 h-[200px] overflow-auto scrollbar-custom accordion content">
                                <div className="flex flex-col w-full">
                                    {Categories.map((name,index) => (
                                       <div key={index} className="flex items-center justify-start w-full space-x-2 py-3">
                                       <div className="min-w-[80%] flex gap-1">
                                           <div className="checkbox-wrapper-46">
                                               <input className="inp-cbx" id={name} type="checkbox" checked={selectedCategories.includes(name)} onChange={() => handleCategoriesChange(name)}/>
                                               <label className="cbx w-full" for={name}><span>
                                                 <svg width="12px" height="10px" viewbox="0 0 12 10">
                                                   <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                 </svg></span><span className="xlg:text-[15px] text-[13px] hiddenOne uppercase">{name}</span>
                                               </label>
                                            </div>          
                                       </div>
                                      </div>
                                    ))}
                                </div>
                                </div>
                              )}
                              <div className="w-full py-6 flex items-center justify-center accordion trigger">
                                     <div className="flex items-center justify-between w-[70%] cursor-pointer" onClick={() => toggleAccordion("brands")}>
                                         <div className="text-[20px] font-extrabold uppercase filter-link">brands</div>
                                         <div className="rounded-full p-2 text-[17px]">{accordionList.brands ? (<IoIosArrowUp className="text-[14px]"/>) : (<IoIosArrowDown className="text-[14px]"/>)}</div>
                                     </div>
                              </div>
                              {accordionList.brands && (
                                <div className="w-full px-6 h-[200px] overflow-auto scrollbar-custom accordion content">
                                <div className="flex flex-col w-full">
                                    {Brands.map((name,index) => (
                                      <div key={index} className="flex items-center justify-start w-full space-x-2 py-3">
                                      <div className="min-w-[80%] flex gap-1">
                                          <div className="checkbox-wrapper-46">
                                              <input className="inp-cbx" id={name} type="checkbox" checked={selectedBrands.includes(name)} onChange={() => handleBrandsChange(name)}/> 
                                              <label className="cbx w-full" for={name}><span>
                                                <svg width="12px" height="10px" viewbox="0 0 12 10">
                                                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                </svg></span><span className="xlg:text-[15px] text-[13px] hiddenOne uppercase">{name}</span>
                                              </label>
                                           </div>          
                                      </div>
                                      </div>
                                    ))}
                                </div>
                                </div>
                              )}
                              <div className="w-full py-6 flex items-center justify-center accordion trigger">
                                     <div className="flex items-center justify-between w-[70%] cursor-pointer" onClick={() => toggleAccordion("prix")}>
                                         <div className="text-[20px] font-extrabold uppercase filter-link">prix</div>
                                         <div className="rounded-full p-2 text-[17px]">{accordionList.prix ? (<IoIosArrowUp className="text-[14px]"/>) : (<IoIosArrowDown className="text-[14px]"/>)}</div>
                                     </div>
                              </div>
                              {accordionList.prix && (
                                 <div className="w-full px-2 h-[200px] overflow-auto scrollbar-custom accordion content">
                                 <div className="flex flex-col  w-full">
                                         <div className="relative w-full items-center justify-center ">
                                             <input
                                                 type="range"
                                                 min="0"
                                                 max="600"
                                                 value={selectedPriceRange} // Bind the value to the state
                                                 id="lower"
                                                 onChange={(e) => setSelectedPriceRange(e.target.value)} // Update the state on slider change
                                             />
                                         </div>   
                                         <div className="flex items-center justify-start">
                                             <span className="text-[14px]">Prix:0TND -- {selectedPriceRange}TND</span>
                                         </div>      
                                         <div className="w-full flex items-center justify-start">
                                             <button className="w-full rounded-2xl bg-black text-white hover:bg-white py-1 mt-2 text-[15px] hover:text-black border border-black">CHERCHER</button>        
                                         </div>                                    
                                 </div>
                                 </div>
                              )}
                           </div>
                      </div>
                  </div>
                  </div>
                )}
           </div>
        </div>
    </div>
  )
}

export default Boutique