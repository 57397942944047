import React,{useState} from 'react'
import { Breadcrumb } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { HiHome } from "react-icons/hi";
import {Link} from "react-router-dom"
import { toast } from 'sonner';
import axios from 'axios'
import { FaArrowLeft, FaShoppingCart } from "react-icons/fa";
import { useCart } from '../contexts/PanierContext';
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function Validation() {
  const { Cart, totalPrice } = useCart();
  const [produitsSlide, setproduitsSlide] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    nom: '',
    prénom: '',
    téléphone: '',
    autreTéléphone: '',
    adresse: '',
    ville: '',
    région: '',
    codePostal: '',
  });
  const [Loading, setLoading] = useState(false);

  const validate = () => {
    let isValid = true;

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
        toast.error('E-mail requis');
        isValid = false;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('E-mail non valide');
        isValid = false;
    }

    // Validate phone number (8 digits)
    if (!formData.téléphone) {
        toast.error('Numéro de téléphone requis');
        isValid = false;
    } else if (formData.téléphone.length !== 8) {
        toast.error('Le numéro de téléphone doit avoir 8 chiffres');
        isValid = false;
    }

    // Check if phone numbers are not the same
    if (formData.autreTéléphone && formData.téléphone === formData.autreTéléphone) {
        toast.error('Le numéro de téléphone ne peut pas être identique au numéro principal');
        isValid = false;
    }

    // Check required fields
    ['nom', 'prénom', 'adresse', 'ville', 'région', 'codePostal'].forEach(field => {
        if (!formData[field]) {
            toast.error(`${field} requis`);
            isValid = false;
        }
    });

    return isValid;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const produits = [];
      Cart.forEach((produit) => {
        produits.push({
          image: produit.image,
          quantité: produit.quantity,
          titre: produit.titre
        });
      });
      
      const commandeInfo = {
        ...formData,
        prix: Number(totalPrice),
        produits: produits
      };
      
      setLoading(true);
      try {
        const response = await axios.post(`/.netlify/functions/addCommande`, commandeInfo);
        if (response.data.Status === "Success") {
          setLoading(false);
          toast.success('Commande est créée');
          navigate("/");
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  

  return (
    <div className="font-[sans-serif] bg-white p-4">
      <div className="md:max-w-5xl max-w-xl mx-auto">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 max-md:order-1">
            <h2 className="text-3xl font-extrabold text-gray-800">Validation</h2>
            <Breadcrumb aria-label="Panier Breadcrumb" className="mt-3">
                <Breadcrumb.Item href="/" icon={HiHome} className="flex gap-2 text-[0px]">
                  <p className="px-1">Home</p>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/Panier" icon={FaShoppingCart}>
                  <p className="px-1">Panier</p>
                 </Breadcrumb.Item>
                <Breadcrumb.Item href="/Validation">
                  <p className="px-1">Validation</p>
                 </Breadcrumb.Item>
              </Breadcrumb>

              <form className="mt-8 max-w-lg" onSubmit={handleSubmit}>
                <div className="grid gap-4">
                  <div className="relative">
                    <input          
                      type="email"
                      placeholder=" "
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor="email"
                      className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                    >
                      E-mail
                    </label>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder=" "
                        id="nom"
                        value={formData.nom}
                        onChange={handleChange}
                        className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="nom"
                        className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                      >
                        Nom
                      </label>
                    </div>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder=" "
                        id="prénom"
                        value={formData.prénom}
                        onChange={handleChange}
                        className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="prénom"
                        className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                      >
                        Prénom
                      </label>
                    </div>
                  </div>

                  <div className="relative">
                   <input
                      type="text"
                      placeholder=" "
                      id="pays"
                     value="Tunisie"
                     className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                       htmlFor="pays"
                       className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                     >
                      Pays
                    </label>
                  </div>
                  <div className="relative">
                   <input
                      type="text"
                      placeholder=" "
                      id="téléphone"
                     value={formData.téléphone}
                      onChange={handleChange}
                     className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                       htmlFor="téléphone"
                       className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                     >
                      Numéro de téléphone
                    </label>
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      placeholder=" "
                      id="adresse"
                      value={formData.adresse}
                      onChange={handleChange}
                      className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor="adresse"
                      className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                    >
                      Adresse
                    </label>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder=" "
                        id="ville"
                        value={formData.ville}
                        onChange={handleChange}
                        className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="ville"
                        className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                      >
                        Ville
                      </label>
                    </div>
                    <div className="relative">
                      <select id="région"
                        value={formData.région}
                        onChange={handleChange}
    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer">
    <option value="" disabled selected>Choisissez une région</option>
    <option value="Ariana">Ariana</option>
    <option value="Béja">Béja</option>
    <option value="Ben Arous">Ben Arous</option>
    <option value="Bizerte">Bizerte</option>
    <option value="Gabès">Gabès</option>
    <option value="Gafsa">Gafsa</option>
    <option value="Jendouba">Jendouba</option>
    <option value="Kairouan">Kairouan</option>
    <option value="Kasserine">Kasserine</option>
    <option value="Kébili">Kébili</option>
    <option value="Le Kef">Le Kef</option>
    <option value="Mahdia">Mahdia</option>
    <option value="La Manouba">La Manouba</option>
    <option value="Médenine">Médenine</option>
    <option value="Monastir">Monastir</option>
    <option value="Nabeul">Nabeul</option>
    <option value="Sfax">Sfax</option>
    <option value="Sidi Bouzid">Sidi Bouzid</option>
    <option value="Siliana">Siliana</option>
    <option value="Sousse">Sousse</option>
    <option value="Tataouine">Tataouine</option>
    <option value="Tozeur">Tozeur</option>
    <option value="Tunis">Tunis</option>
    <option value="Zaghouan">Zaghouan</option>
                      </select>
                      <label for="région" className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">Région</label>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
          <div className="relative">
            <input
              type="number"
              placeholder=" "
              id="codePostal"
              value={formData.codePostal}
              onChange={handleChange}
              className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
            <label
              htmlFor="codePostal"
              className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Code Postal
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder=" "
              id="autreTéléphone"
              value={formData.autreTéléphone}
              onChange={handleChange}
              className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-800 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
            <label
              htmlFor="autreTéléphone"
              className="absolute text-sm text-gray-800 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Autre numéro de téléphone
            </label>
          </div>
                  </div>
                  <div className="flex w-full justify-between xs:flex-row flex-col items-center py-8">
                            {!Loading ? (
                             <Link to="/Panier" className="flex gap-2 hover:text-blue-400 cursor-pointer items-center">
                                <FaArrowLeft />
                                <p className="">Retourner</p>
                             </Link>
                             ) : (
                               <div className="flex gap-2 hover:text-blue-400 cursor-pointer items-center">
                                  <FaArrowLeft />
                                  <p className="">Retourner</p>
                               </div>
                             )}
                           {Loading ? <button  className="w-40 py-3.5 text-sm bg-blue-500 flex items-center justify-center text-white rounded-md hover:bg-blue-600 tracking-wide"><svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></button> :  <button type="submit" className="w-40 py-3.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 tracking-wide">COMMANDER</button>}                 
                   </div>
                </div>
              </form>
          </div>

          <div className="bg-gray-100 p-6 rounded-md">
            <div onClick={() => setproduitsSlide(!produitsSlide)} className="w-full bg-black text-center px-2 items-center text-[13px] justify-center flex gap-2 cursor-pointer py-1 rounded-md text-white">INFORMATION DE LES PRODUITS {!produitsSlide ? (<MdOutlineKeyboardArrowDown className="text-[18px]"/>) : (<MdOutlineKeyboardArrowUp className="text-[18px]"/>)}</div>
            {produitsSlide && (
               <div className="w-full flex flex-col h-[200px] overflow-auto scrollbar-custom">
                  {Cart.map((produit) => (
                     <div className="w-full flex gap-2 py-2">
                       <img src={produit.image} alt="produit" className="w-[80px] h-[80px]"/>
                       <div className="flex flex-col gap-[2px]">
                          <p className="text-[12px] font-extrabold truncate-multiline uppercase">{produit.titre}</p>
                          <p className="text-[10px] font-medium uppercase">{produit.categorie}</p>
                          <p className="           text-[10px] font-medium">{produit.prix.toFixed(3)}TND</p>
                       </div>
                     </div>
                  ))}
               </div>
            )}

            <ul className="text-gray-800 mt-8 space-y-4">
              <li className="flex flex-wrap gap-4 text-sm">Sous-total <span className="ml-auto font-bold">{totalPrice}TND</span></li>
              <li className="flex flex-wrap gap-4 text-sm">Livraison <span className="ml-auto font-bold">0TND</span></li>
              <li className="flex flex-wrap gap-4 text-sm font-bold border-t-2 pt-4">Total <span className="ml-auto">{totalPrice}TND</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Validation