import React from 'react'
import BrandsSlider from './BrandsSlider';

function Brands() {
  return (
    <div className="w-full px-3 py-16">
       <div className="w-full flex-col sm:px-8">
           <div className="w-full flex items-center mb-6  justify-start">
               <h1 className="sm:text-[40px] text-[30px] Linky">BRANDS</h1>
           </div>
           <BrandsSlider />
       </div>
    </div>
  )
}

export default Brands