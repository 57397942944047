import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { HiHome } from "react-icons/hi";
import { Breadcrumb } from "flowbite-react";
import { TbBrandDatabricks } from "react-icons/tb";

const brands = [
    { id: 1, imgSrc: "/Brands/imageOne.png", name: "Optimum nutrition" },
    { id: 2, imgSrc: "/Brands/imageTwo.png", name: "Muscle Tech" },
    { id: 3, imgSrc: "/Brands/imageThree.png", name: "Biotech USA" },
    { id: 4, imgSrc: "/Brands/imageFour.png", name: "Nutrex" },
    { id: 5, imgSrc: "/Brands/imageFive.png", name: "Nutrend" },
    { id: 6, imgSrc: "/Brands/imageSix.png", name: "Ultimate Nutrition" },
    { id: 7, imgSrc: "/Brands/imageSeven.png", name: "Applied Nutrition" },
    { id: 8, imgSrc: "/Brands/imageEight.png", name: "Olimp" },
    { id: 9, imgSrc: "/Brands/imageNine.png", name: "Hx Nutrition" },
    { id: 10, imgSrc: "/Brands/imageTen.png", name: "Real Pharm" },
    { id: 11, imgSrc: "/Brands/imageEleven.png", name: "Impact Nutrition" },
];

function Brands() {
  return (
    <section className="relative z-10 overflow-hidden bg-gray-100 py-20 dark:bg-dark lg:pb-[120px] lg:pt-[60px]">
      <div className="flex flex-col">
        <h1 className="mb-8 sm:mx-[120px] mx-4 sm:text-[60px] text-[40px] font-bold uppercase Linky">
          Brands
        </h1>
        <Breadcrumb aria-label="Panier Breadcrumb" className="mb-10 sm:mx-[120px] mx-2">
              <Breadcrumb.Item href="/" icon={HiHome} className="flex gap-2 text-[0px]">
                <p className="px-1">Home</p>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/Brands" icon={TbBrandDatabricks}>
                <p className="px-1">Brands</p>
              </Breadcrumb.Item>
        </Breadcrumb>
        <div className="w-full py-12 xs:px-4 sm:px-6 flex flex-wrap justify-center gap-6">
          {brands.map((brand) => (
            <div 
              key={brand.id} 
              className="relative w-[200px] h-[200px] p-4 bg-white rounded-xl shadow-md"
              data-tooltip-id="brandName" data-tooltip-content={brand.name}
            >
              <img src={brand.imgSrc} alt={`${brand.name} Logo`} className="w-full h-full object-contain" />
            </div>
          ))}
        </div>
        <ReactTooltip id="brandName" place="bottom" effect="solid"/>
      </div>
    </section>
  );
}

export default Brands;
