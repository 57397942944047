import {React ,useEffect,useState} from 'react'
import { Link } from "react-router-dom";
import Loading from '../../components/Loading';
import axios from 'axios'

const AdminHomePage = ({Produits}) => {
  const [Admin, setAdmin] = useState({})
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchAdmin');
        if(response.data.Status === "Success") {
         setAdmin(response.data.Data);
         setisLoading(false);
        }
      } catch (err) {
        console.error(err)
      }
    };
    fetchAdmin();
  }, []);
  if(isLoading){
    return <Loading/>
  }
  const calcPour = (oldPrice, newPrice) => {
    const discount = oldPrice - newPrice;
    const discountPercentage = (discount / oldPrice) * 100;
  
    return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
  }
  return (
    <div className="flex flex-grow">
       <div className="p-2 flex flex-col w-full">
           <div className="flex items-center w-full justify-center">
              <h1 className="text-[50px] Linky uppercase pt-6">Bienvenue Atef</h1>
           </div>
           <div className="flex w-full pt-8 px-[70px] gap-4 flex-wrap">
               <div className="bg-blue-900 h-[200px] flex flex-col gap-2 p-2 text-center w-[250px] rounded-xl">
                   <span className="uppercase text-gray-100 text-[15px]">argent totale</span>
                   <span className="text-[40px] text-white">{Admin.totalArgent} TND</span>
               </div>
               <div className="bg-blue-900 h-[200px] flex flex-col gap-2 p-2 text-center w-[250px] rounded-xl">
                   <span className="uppercase text-gray-100 text-[15px]">nombre de commandes</span>
                   <span className="text-[40px] text-white">{Admin.totalCommandesVérifié} COM</span>
               </div>
               <div className="bg-blue-900 h-[200px] flex flex-col gap-2 p-2 text-center w-[250px] rounded-xl">
                   <span className="uppercase text-gray-100 text-[15px]">Nombre de produits</span>
                   <span className="text-[40px] text-white">{Produits.length} PRO</span>
               </div>
           </div>
           <div className="w-full px-[40px] pt-[40px] h-[700px] overflow-auto scrollbar-custom">
               <div className="flex w-full flex-col">
                   <div className="w-full flex mb-8 items-center justify-start">
                       <h1 className="uppercase Linky text-[50px]">Produits</h1>
                   </div>
                   {Produits.length !== 0 && (
                        <div className="lg:px-0 md:px-20 sm:px-5 xmd:px-2 xxmd:px-20 px-4 gap-4 grid  lg:grid-cols-3 xmd:grid-cols-2 grid-cols-1">
                         {Produits.map((produit,index) => (
                          <div key={index} className="h-[400px]  relative overflow-y-hidden bg-gray-200 rounded-xl shadow group cursor-pointer">
                                {produit.ensolde && (<div className="absolute top-3 left-3 bg-red-500 diagonal-rectangle rounded-sm text-[12px] text-white p-1">{calcPour(produit.solde.oldprix,produit.prix)}% OFF</div>)}
                                <Link to={`/produit/${produit.titre}`} className="flex flex-col">
                                   <div className="flex w-full h-[250px]">
                                     <img src={produit.image} alt="l'image de produit" className="rounded-t-xl w-full h-full"/>
                                   </div>
                                   <div className="flex flex-col w-full p-4 gap-[5px]">
                                         <h1 className="truncate-multiline hover:text-red-600 cursor-pointer uppercase">{produit.titre}</h1>
                                         <p className="hover:text-red-600 underline cursor-pointer text-[14px] text-gray-400 uppercase">{produit.categorie}</p>
                                         <div className="flex gap-2 items-center">
                                            {produit.ensolde && (
                                               <p className="line-through text-gray-400 text-[14px]">{produit.solde.oldprix.toFixed(3)}TND</p>
                                            )}
                                            <p className="text-red-500 text-[17px]">{produit.prix.toFixed(3)}TND</p>
                                         </div>
                                   </div>
                                </Link>
                           </div>      
                          ))} 
                        </div>
                    )}  
               </div>
           </div>
       </div>
    </div>
  );
};

export default AdminHomePage;
