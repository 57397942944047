import React, {useState,useEffect} from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import axios from 'axios'
import {toast} from 'sonner'
import { IoClose } from "react-icons/io5";
import Loading from "../../components/Loading";

function timeAgo(dateString) {
  const now = new Date();
  const date = new Date(dateString);  // Convert the input to a Date object
  const diffInSeconds = Math.floor((now - date) / 1000); // Difference in seconds
  const units = [
    { name: 'année', seconds: 31536000 }, // 1 year = 365 days * 24 hours * 60 minutes * 60 seconds
    { name: 'mois', seconds: 2592000 },   // 1 month = 30 days * 24 hours * 60 minutes * 60 seconds
    { name: 'jour', seconds: 86400 },     // 1 day = 24 hours * 60 minutes * 60 seconds
    { name: 'heure', seconds: 3600 },     // 1 hour = 60 minutes * 60 seconds
    { name: 'minute', seconds: 60 },      // 1 minute = 60 seconds
    { name: 'seconde', seconds: 1 }       // 1 second
  ];
 
  for (let unit of units) {
    const interval = Math.floor(diffInSeconds / unit.seconds);
    if (interval >= 1) {
      return `Il y a ${interval} ${unit.name}${interval > 1 ? 's' : ''}`;
    }
  }
   
  return "À l'instant"; // Just now
}
const Commandes = () => {
  const [commandeView, setcommandeView] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [sureModal, setsureModal] = useState(false);
  const [commandeNuméro, setcommandeNuméro] = useState(0);
  const [verifyLoading, setverifyLoading] = useState(false);
  const [Commandes, setCommandes] = useState([]);
  const [currentCommande, setcurrentCommande] = useState({});
  useEffect(() => {
    const fetchProduits = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchCommandes');
        if(response.data.Status === "Success") {
         setCommandes(response.data.Data);
         setisLoading(false);
        }
      } catch (err) {
        console.error(err)
      }
    };
    fetchProduits();
  }, []);

  const viewCommande = (commande) => {
    setcurrentCommande(commande)
    setcommandeView(true)
  }
  const setModal = (commande) => {
    setsureModal(true)
    setcommandeNuméro(commande.numéro)
  }

  const handleDeleteCommande = async () => {
    setverifyLoading(true);
     try {
        const response = await axios.get(`/.netlify/functions/deleteCommande`, {
          params: { numéro:commandeNuméro },
        });
        if (response.data.Status === "Success") {
          setverifyLoading(false);
          setsureModal(false)
          toast.success('Commande Est Supprimé');
        }
      } catch (err) {
        console.error(err);
      }
  }

  const handleSubmitVerification = async () => {
    if(currentCommande.verifié){
      toast.error("Commande est déja vérifié")
    } else {
      setverifyLoading(true);
      try {
         const response = await axios.get(`/.netlify/functions/verifyCommande`, {
           params: { numero:currentCommande.numéro },
         });
         console.log(response.data)
         if (response.data.Status === "Success") {
           setverifyLoading(false);
           setcommandeView(false)
           toast.success('Commande Est Vérifié');
         }
       } catch (err) {
         console.error(err);
       }
    }
  }
  if(isLoading){
   return (<Loading />)
  }
  return (
      <section className="flex flex-grow">
        <div className="w-full flex flex-col p-4">
            <div className="w-full flex items-center px-4 justify-start">
                <h1 className="Linky md:text-[50px] text-[25px]">Commandes</h1>
            </div>
            {commandeView && (
             <div className="absolute top-0 z-30 left-0 w-screen xmd:h-full h-screen bg-white p-2">
        <div className="relative flex gap-4 xs:items-center xs:justify-center w-full h-full flex-col">
            <div onClick={() => !verifyLoading && setcommandeView(false)} className="cursor-pointer absolute top-0 right-0 p-2 text-[30px] hover:bg-gray-200 rounded-full">
                <IoClose />
            </div>
            <div className="max-w-[600px] xmd:min-w-[450px]  flex gap-2 flex-col">
                <div className="w-full flex mb-6 xmd:mt-0 xs:mt-6 mt-12 items-center justify-center">
                    <h1 className="xmd:text-[30px] text-[20px]">Commande Numéro {currentCommande.numéro}</h1>
                </div>
                <div className="flex xmd:items-center xmd:gap-0 gap-2 justify-start xmd:justify-between xmd:flex-row flex-col w-full">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Nom: <span className="text-gray-600">{currentCommande.nom}</span>
                    </p>
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Prénom: <span className="text-gray-600">{currentCommande.prénom}</span>
                    </p>
                </div>
                <div className="flex items-center justify-start w-full">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Email: <span className="text-gray-600">{currentCommande.email}</span>
                    </p>
                </div>
                <div className="flex items-center justify-start w-full">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Téléphone: <span className="text-gray-600">{currentCommande.téléphone}</span>
                    </p>
                </div>
                <div className="flex items-center justify-start w-full">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Adresse: <span className="text-gray-600">{currentCommande.adresse}</span>
                    </p>
                </div>
                <div className="flex xmd:items-center xmd:gap-0 gap-2 justify-start xmd:justify-between xmd:flex-row flex-col w-full">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Ville: <span className="text-gray-600">{currentCommande.ville}</span>
                    </p>
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Région: <span className="text-gray-600">{currentCommande.région}</span>
                    </p>
                </div>
                <div className="flex xmd:items-center xmd:gap-0 gap-2 justify-start xmd:justify-between xmd:flex-row flex-col w-full">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Code Postal: <span className="text-gray-600">{currentCommande.codePostal}</span>
                    </p>
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Autre Téléphone: <span className="text-gray-600">{currentCommande.autreTéléphone}</span>
                    </p>
                </div>
                <div className="flex items-center justify-start w-full">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">
                        Prix: <span className="text-gray-600">{currentCommande.prix.toFixed(3)} TND</span>
                    </p>
                </div>
                <div className="flex items-center justify-start flex-col w-full min-h-[100px] max-h-[200px]">
                    <p className="xmd:text-[17px] text-[14px] flex gap-2">Produits:</p>
                    <div className="flex flex-col gap-2 w-full">
                        {currentCommande.produits.map((produit, index) => (
                            <div key={index} className="flex w-full items-center gap-2 justify-center">
                                <img src={produit.image} alt="produit" className="xmd:w-[25px] w-[17px] h-[17px] xmd:h-[25px]" />
                                <p className="xmd:text-[15px] text-[12px] flex gap-2">
                                    <span className="truncate max-w-[200px]">{produit.titre}</span> × <span>{produit.quantité}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-full flex items-center justify-center">
                    <div className="flex xs:flex-row flex-col gap-4 xs:w-auto w-full">
                        <button onClick={() => !verifyLoading && setcommandeView(false)} className="text-[15px] uppercase px-8 py-1 xs:w-auto w-full bg-white text-black border border-black rounded-xl">Annuler</button>
                        {verifyLoading ? (
                            <button className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl xs:w-auto w-full">
                                <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> 
                                </svg>
                            </button>
                        ) : (
                            <button onClick={handleSubmitVerification} className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl xs:w-auto w-full">Valider</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
             </div>
            )}
            {sureModal && (
             <div className="absolute w-full h-full top-0 left-0 z-30 bg-black bg-opacity-40 flex items-center justify-center">
                <div className="bg-white rounded-xl p-4 max-w-[400px]">
                    <div className="flex flex-col gap-4">
                        <h1 className="text-[20px] text-center">Vous étes sure de supprimer la commande?</h1>
                        <div className="w-full gap-2 items-center justify-center flex">
                            <button onClick={() => !verifyLoading && setsureModal(false)} className="text-[15px] uppercase px-8 py-1 bg-white text-black border border-black rounded-xl">Annuler</button>
                            {verifyLoading ? (<button className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl"><svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></button>) : (<button onClick={handleDeleteCommande} className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl">Supprimer</button>)}
                        </div>
                    </div>
                </div>
             </div>
            )}
            <div className="w-full xs:px-[20px] pt-[40px] h-[700px] overflow-auto scrollbar-custom">
                <div className="flex w-full flex-col">
                         {Commandes.length !== 0 ? (
                                <div className="w-full sm:hidden gap-4 flex flex-col xs:px-[30px]">
                                   {Commandes.map((commande,index) => (
                                      <div key={index} className="w-full bg-gray-100 relative border-l-[5px] border-blue-500 p-4 min-h-[200px]">
                                        <div onClick={() => setModal(commande)} className="absolute top-0 right-0 p-2">
                                             <FaRegTrashAlt className="text-[14px]"/>
                                        </div>
                                        <div className="flex flex-col gap-2 pt-4">
                                            <p className="truncate text-[20px]">Nom: {commande.nom}.{commande.prénom.substring(0,1)}</p>
                                            <p className="truncate text-[17px]">Numéro: {commande.numéro}</p>
                                            <p className="truncate text-[17px]">Prix: {commande.prix.toFixed(3)}TND</p>
                                            <p className="truncate text-[17px]">Prix: {timeAgo(commande.createdAt)}</p>
                                            <span className="truncate text-[17px] flex gap-2">Status: 
                                              {commande.verifié ? (
                                                 <p className="bg-green-500 text-green-800 rounded-xl uppercase py-1 px-4 text-[13px]">vérifié</p>
                                              ) : (
                                                <p className="bg-red-500 text-red-800 rounded-xl uppercase py-1 px-4 text-[13px]">non vérifié</p>
                                              )}
                                            </span>
                                            <span onClick={() => viewCommande(commande)} className="truncate text-[17px] flex">
                                                <p className="bg-blue-400 px-5 py-1 text-center uppercase text-white text-[13px] rounded-xl cursor-pointer hover:bg-blue-600 transition">plus</p>
                                            </span>
                                        </div>
                                      </div>
                                   ))}
                                </div>
                            ) : (
                                <div className="text-center">No Commandes</div>
                          )}
                        <table className="sm:table hidden w-full border-collapse text-left">
                            <thead className="bg-neutral-800">
                                <tr>
                                    <th className="border-b p-4 text-white">Nom De Personne</th>
                                    <th className="border-b p-4 text-white">Numéro</th>
                                    <th className="border-b p-4 text-white">Prix</th>
                                    <th className="border-b p-4 text-white">Status</th>
                                    <th className="border-b p-4 text-white">Crée At</th>
                                    <th className="border-b p-4 text-white"></th>
                                    <th className="border-b p-4 text-white"></th>
                                </tr>
                            </thead>
                            {Commandes.length !== 0 ? (
                                 <tbody>
                                   {Commandes.map((commande,index) => (
                                      <tr key={index} className="border-b">
                                          <td className="p-4 flex gap-2 max-w-[300px]">
                                              <p className="truncate text-[13px]">{commande.nom}.{commande.prénom.substring(0,1)}</p>
                                          </td>
                                          <td className="p-4 text-[13px]">{commande.numéro}</td>
                                          <td className="p-4 text-[13px]">{commande.prix.toFixed(3)}TND</td>
                                          <td className="p-4 text-[13px]">{timeAgo(commande.createdAt)}</td>
                                          <td className="p-4 flex items-center">
                                              {commande.verifié ? (
                                                 <p className="bg-green-500 text-green-800 rounded-xl uppercase py-1 px-4 text-[13px]">vérifié</p>
                                              ) : (
                                                <p className="bg-red-500 text-red-800 rounded-xl uppercase py-1 px-4 text-[13px]">non vérifié</p>
                                              )}
                                          </td>
                                          <td onClick={() => viewCommande(commande)} className="p-4">
                                              <p className="bg-blue-400 px-3 py-1 text-center uppercase text-white text-[13px] rounded-xl cursor-pointer hover:bg-blue-600 transition">plus</p>
                                          </td>
                                          <td onClick={() => setModal(commande)}className="p-4 text-[13px] cursor-pointer">
                                              <FaRegTrashAlt />
                                          </td>
                                      </tr>
                                   ))}
                                </tbody>
                            ) : (
                                <div className="text-center">No Produits</div>
                            )}
                        </table>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Commandes;