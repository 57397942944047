import React, { useState } from 'react';
import { HiHome } from "react-icons/hi";
import { Breadcrumb } from "flowbite-react";
import { FaCalculator } from "react-icons/fa";
import { IoMdAlert } from 'react-icons/io';

function Calories() {
  const [form, setForm] = useState({
    height: '',
    weight: '',
    age: '',
    gender: '',
  });
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const calculateBMI = (e) => {
    e.preventDefault();
    const { height, weight, age, gender } = form;
  
    if (!height || !weight || !age || !gender) {
      setError('Veuillez remplir tous les champs.');
      return;
    }
  
    const heightInMeters = parseFloat(height) / 100;
    const weightInKg = parseFloat(weight);
    const bmiValue = weightInKg / (heightInMeters ** 2);
  
    let bmiStatus = '';
  
    if (age < 18) {
      bmiStatus = 'Jeune';
    } else {
      if (gender === 'Homme') {
        if (bmiValue < 18.5) {
          bmiStatus = 'Insuffisance pondérale';
        } else if (bmiValue >= 18.5 && bmiValue < 24.9) {
          bmiStatus = 'Poids normal';
        } else if (bmiValue >= 25 && bmiValue < 29.9) {
          bmiStatus = 'Surpoids';
        } else {
          bmiStatus = 'Obésité';
        }
      } else if (gender === 'Femme') {
        if (bmiValue < 18) {
          bmiStatus = 'Insuffisance pondérale';
        } else if (bmiValue >= 18 && bmiValue < 24) {
          bmiStatus = 'Poids normal';
        } else if (bmiValue >= 24 && bmiValue < 30) {
          bmiStatus = 'Surpoids';
        } else {
          bmiStatus = 'Obésité';
        }
      }
  
      // Additional adjustment for older age (e.g., 65+)
      if (age >= 65) {
        if (bmiValue >= 25 && bmiValue < 27) {
          bmiStatus = 'Poids normal pour les personnes âgées';
        } else if (bmiValue >= 27) {
          bmiStatus = 'Surpoids';
        }
      }
    }
  
    setStatus(bmiStatus);
    setError('');
  };

  return (
    <section className="relative z-10 overflow-hidden bg-gray-100 py-20 dark:bg-dark lg:pb-[120px] lg:pt-[60px]">
      <div className="flex flex-col">
        <h1 className="mb-2 sm:mx-[120px] mx-4 sm:text-[60px] text-[40px] font-bold Linky uppercase">
          BMI Calculator
        </h1>
        <Breadcrumb aria-label="Panier Breadcrumb" className="mb-10 sm:mx-[120px] mx-2">
              <Breadcrumb.Item href="/" icon={HiHome} className="flex gap-2 text-[0px]">
                <p className="px-1">Home</p>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/Calories" icon={FaCalculator}>
                <p className="px-1">Calories</p>
              </Breadcrumb.Item>
        </Breadcrumb>
        <div className="w-full py-12 sm:px-6 px-1 flex minlg:flex-row flex-col">
          <div className="minlg:w-1/2 w-full minlg:min-h-[500px] sm:min-h-[400px] min-h-[300px]">
            <div className="mt-8">
              <h3 className="text-center font-bold Linky text-[30px] mb-4 px-2">Tableau des Statuts IMC</h3>
              <table className="table-auto w-full border-collapse border border-black">
                <thead>
                  <tr>
                    <th className="border border-blue-600 px-4 py-2 bg-blue-600 text-white border-r-white">IMC</th>
                    <th className="border border-blue-600 px-4 py-2 bg-blue-600 text-white">Statut</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">&lt; 18.5</td>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">Insuffisance pondérale</td>
                  </tr>
                  <tr>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">18.5 - 24.9</td>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">Poids normal</td>
                  </tr>
                  <tr>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">25 - 29.9</td>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">Surpoids</td>
                  </tr>
                  <tr>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">&gt;= 30</td>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">Obésité</td>
                  </tr>
                  <tr>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">65+ (25 - 27)</td>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">Poids normal pour les personnes âgées</td>
                  </tr>
                  <tr>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">65+ (&gt; 27)</td>
                    <td className="border border-blue-600 px-4 py-2 text-blue-600">Surpoids</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="sm:px-4 minlg:w-1/2 w-full">
            <div className="relative rounded-lg px-8 py-12 dark:bg-dark-2 sm:p-12">
              <form onSubmit={calculateBMI}>
                {error && (
                  <div className="bg-red-400 text-white flex gap-2 items-center justify-center w-full py-2 rounded-xl mb-4">
                    <IoMdAlert />
                    {error}
                  </div>
                )}
                <ContactInputBox
                  type="text"
                  name="height"
                  placeholder="Votre taille (cm)"
                  value={form.height}
                  onChange={handleChange}
                />
                <ContactInputBox
                  type="text"
                  name="weight"
                  placeholder="Votre poids (kg)"
                  value={form.weight}
                  onChange={handleChange}
                />
                <ContactInputBox
                  type="text"
                  name="age"
                  placeholder="Votre âge"
                  value={form.age}
                  onChange={handleChange}
                />
                <div className="mb-6">
                  <select
                    name="gender"
                    value={form.gender}
                    onChange={handleChange}
                    className="w-full border-blue-600 placeholder-blue-600 text-blue-600 rounded border border-stroke px-[14px] py-3 bg-transparent text-base outline-none focus:border-primary"
                  >
                    <option value="">Sélectionner le sexe</option>
                    <option value="Homme">Homme</option>
                    <option value="Femme">Femme</option>
                  </select>
                </div>
                <div>
                  <button
                    type="submit"
                    className={`w-full bg-blue-600 border-blue-600 flex items-center justify-center hover:bg-transparent hover:text-blue-600 rounded border border-primary bg-primary p-3 text-white transition`}
                  >
                    VÉRIFIER
                  </button>
                </div>
              </form>
              {status && (
                <div className="mt-6 text-center">
                  <h3>Votre statut: {status}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Calories;

const ContactInputBox = ({ type, placeholder, name, value, onChange }) => {
  return (
    <div className="mb-6">
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full border-blue-600 placeholder-blue-600 text-blue-600 rounded border border-stroke px-[14px] py-3 bg-transparent text-base outline-none focus:border-primary"
      />
    </div>
  );
};
