import { createContext, useContext, useState } from 'react';

// Create the DisclosureContext
const DisclosureContext = createContext();

export const DisclosureProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <DisclosureContext.Provider value={{ open, setOpen }}>
      {children}
    </DisclosureContext.Provider>
  );
};

// Custom hook to use the DisclosureContext
export const useDisclosure = () => {
  return useContext(DisclosureContext);
};
