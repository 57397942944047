import React from 'react'
import { IoCall } from "react-icons/io5";

function UpBar() {
  return (
    <div className="w-screen bg-blue-400 p-3">
      <div className="w-full flex items-center justify-center sm:gap-20 gap-4">
         <div className="glowing-text-red sm:flex hidden">AMELIOREZ VOS PERFORMANCES AVEC NOUS</div>
         <div className="glowing-text-red sm:hidden flex text-[12px]">CONTACTER NOUS</div>
         <div className="flex sm:gap-2 gap-1 text-white mt-[2px] items-center sm:text-[16px] text-[12px]">
           <IoCall />
           57 044 501 / 57 044 510
         </div>
      </div>
    </div>
  )
}

export default UpBar