import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { GoPlus, GoDash } from "react-icons/go";
import { useCart } from '../contexts/PanierContext';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useDisclosure } from '../contexts/DisclosureContext';

function ProductInfo() {
  const [quantity, setquantity] = useState(1);
  const { title } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [Produit, setProduit] = useState([]);
  const [similarProducts, setsimilarProducts] = useState([]);
  const { addCart } = useCart();
  const { setOpen } = useDisclosure();

  useEffect(() => {
    const fetchProduits = async () => {
      try {
        const response = await axios.get(`/.netlify/functions/fetchProductPage`, {
          params: { title },
        });
        if (response.data.Status === "Success") {
          setProduit(response.data.Data);
          setsimilarProducts(response.data.SimilarProducts)
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchProduits();
  }, [title]);
  const calcPour = (oldPrice, newPrice) => {
    const discount = oldPrice - newPrice;
    const discountPercentage = (discount / oldPrice) * 100;
  
    return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
  }
  const handleAddToCart = () => {
    const newProduit = {
      ...Produit,        // Spread all properties from the produit object
      quantity: quantity,
      id: Math.random().toString(36).substr(2, 9), // Generate a random ID     
      livraison:0
    };
    addCart(newProduit); // Add the newProduit to the cart  
    setOpen(true)
  };
  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="py-24 sm:px-10 ">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="slider-box w-full h-full max-lg:mx-auto mx-0">
            <div className="swiper main-slide-carousel swiper-container relative mb-6">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="block">
                    <Zoom>
                      <img
                        src={Produit.image}
                        alt={Produit.titre}
                        className="max-lg:mx-auto rounded-2xl"
                      />
                    </Zoom>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex xxmd:py-12 py-6">
            <div className="pro-detail w-full max-lg:max-w-[608px] lg:pl-8 xl:pl-16 max-lg:mx-auto max-lg:mt-8">
              <div className="py-7 xs:px-7 flex flex-col gap-2 z-0 lg:px-0 lg:py-0 xl:gap-2">
                <div className="flex items-center justify-start">
                  {Produit.instock ? (
                     <span className="font-bold xxmd:text-[10px] text-[9px] tracking-widest px-3 py-1 rounded-xl bg-green-400 text-white uppercase">
                       IN STOCK
                     </span>
                  ) : (
                     <span className="font-bold xxmd:text-[10px] text-[9px] tracking-widest px-3 py-1 rounded-xl bg-red-400 text-white uppercase">
                      OUT OF STOCK
                     </span>
                  )}
                </div>
                <h1 className="font-extrabold text-[20px] xs:text-[24px] xxmd:text-[30px] xxmd:leading-[50px] lg:text-[40px] uppercase">
                  {Produit.titre}
                </h1>
                <div className="flex gap-2 items-center">
                  {Produit.ensolde && (
                    <span className="line-through text-gray-500 xxmd:text-[14px] text-[10px]">
                      {Produit.solde.oldprix.toFixed(3)} TND
                    </span>
                  )}
                  <span className="xxmd:text-[24px] text-[17px] text-red-600 pb-2">
                    {Produit.prix.toFixed(3)} TND
                  </span>
                </div>
                {Produit.instock && (
                  <div className="border w-full flex justify-between items-center rounded-xl border-black py-1 px-3">
                  <div
                    onClick={() => quantity > 1 && setquantity(quantity - 1)}
                    className="text-[15px] cursor-pointer"
                  >
                    <GoDash />
                  </div>
                  <div className="text-[15px]">{quantity}</div>
                  <div
                    onClick={() => quantity < 9 && setquantity(quantity + 1)}
                    className="text-[15px] cursor-pointer"
                  >
                    <GoPlus />
                  </div>
                  </div>
                )}
                {Produit.instock ? (
                  <button onClick={handleAddToCart} className="xxmd:text-[14px] text-[12px] rounded-xl bg-red-600 hover:bg-white border border-red-600 text-white hover:text-red-600 transition-all duration-500 ease-in-out py-2">
                    AJOUTER AU PANIER
                  </button>
                ) : (
                  <button className="xxmd:text-[14px] text-[12px] uppercase rounded-xl cursor-not-allowed bg-white border border-red-600 text-red-600 transition-all duration-500 ease-in-out py-2">
                     n'est pas available
                  </button>
                )}
                <span className="xxmd:text-[14px] text-[12px]">
                  Categorie:{" "}
                  <span className="uppercase text-gray-400">
                    {Produit.categorie}
                  </span>
                </span>
                <span className="xxmd:text-[14px] text-[12px]">
                  Brand:{" "}
                  <span className="uppercase text-gray-400">
                    {Produit.brand}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full py-2 xxmd:mb-0 mb-6">
          <div className="w-full flex flex-col">
            <div className="w-full p-2 border-b">
              <span className="Linky lg:text-[35px] sm:text-[30px] text-[23px]">DESCRIPTION :</span>
            </div>
            <div className="w-full py-2">
              <p className="xxmd:text-[14px] text-[11px]">{Produit.description}</p>
            </div>
          </div>
        </div>
        <div className="w-full">
              <div className="flex flex-col w-full gap-4">
                   <div className="flex items-center justify-start w-full lg:px-17 px-2">
                     <h1 className="lg:text-[35px] sm:text-[30px] text-[23px] Linky">PRODUITS SIMILAIRES :</h1>
                   </div>
                       {similarProducts.length !== 0 ? (
                        <div className="lg:px-17 md:px-2 sm:px-10 xmd:px-2 xxmd:px-20 px-4 gap-4 grid  lg:grid-cols-4 md:grid-cols-3 xmd:grid-cols-2 grid-cols-1">
                         {similarProducts.map((produit) => (
                          <div className="h-[400px]  relative overflow-y-hidden bg-gray-200 rounded-xl shadow group cursor-pointer">
                               <div className="text-white hover:bg-blue-500 group-hover:block absolute w-full left-0 h-[50px] bg-black bottom-0 hidden">
                                   <div className="w-full h-full flex items-center justify-center">
                                      <p>AJOUTER AU PANIER</p>
                                   </div>
                               </div>
                                {produit.ensolde && (<div className="absolute top-3 left-3 bg-red-500 diagonal-rectangle rounded-sm text-[12px] text-white p-1">{calcPour(produit.solde.oldprix,produit.prix)}% OFF</div>)}
                                <Link to={`/produit/${produit.titre}`} className="flex flex-col">
                                   <div className="flex w-full h-[250px]">
                                     <img src={produit.image} alt="l'image de produit" className="rounded-t-xl w-full h-full"/>
                                   </div>
                                   <div className="flex flex-col w-full p-4 gap-[5px]">
                                         <h1 className="truncate-multiline hover:text-red-600 cursor-pointer uppercase">{produit.titre}</h1>
                                         <p className="hover:text-red-600 underline cursor-pointer text-[14px] text-gray-400 uppercase">{produit.categorie}</p>
                                         <div className="flex gap-2 items-center">
                                            {produit.ensolde && (
                                               <p className="line-through text-gray-400 text-[14px]">{produit.solde.oldprix.toFixed(3)}TND</p>
                                            )}
                                            <p className="text-red-500 text-[17px]">{produit.prix.toFixed(3)}TND</p>
                                         </div>
                                   </div>
                                </Link>
                           </div>      
                          ))} 
                          </div>
                       ) : (
                         <div className="flex w-full items-center justify-center xxmd:p-4 p-2">
                            <span className="text-[14px]">No Produits Similaires</span>
                         </div>
                       )}                                    
                 </div>
         </div>
      </div>
    </section>
  );
}

export default ProductInfo;
