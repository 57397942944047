import React from 'react'
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { FaShoppingCart } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/PanierContext';


function Panier() {
  const { Cart, totalPrice,removeCart,changeQuantity } = useCart();
  return (
  <div className="min-h-screen bg-gray-100 pt-14">
    <h1 className="mb-2 sm:mx-[120px] mx-4 sm:text-[60px] text-[30px] font-bold Linky">Panier</h1>
    <Breadcrumb aria-label="Panier Breadcrumb" className="mb-10 sm:mx-[120px] mx-2">
      <Breadcrumb.Item href="/" icon={HiHome} className="flex gap-2 text-[0px]">
        <p className="px-1">Home</p>
      </Breadcrumb.Item>
      <Breadcrumb.Item href="/Panier" icon={FaShoppingCart}>
        <p className="px-1">Panier</p>
      </Breadcrumb.Item>
    </Breadcrumb>
    {Cart.length !== 0 ? (
       <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
       <div className="rounded-lg md:w-2/3">
         {Cart.map((produit) => (
           <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
           <img src={produit.image} alt="product-image" className="w-full rounded-lg sm:w-40" />
           <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
             <div className="mt-5 sm:mt-0">
               <h2 className="text-lg truncate-multiline font-bold text-gray-900 uppercase">{produit.titre}</h2>
               <p className="mt-1 text-xs text-gray-700 uppercase">{produit.categorie}</p>
               <p className="text-[15px]">{produit.prix.toFixed(3)} TND</p>
             </div>
             <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block ">
               <div className="flex items-center border-gray-100">
                 <span onClick={() => changeQuantity(produit.id,produit.quantity - 1)} className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"> - </span>
                 <label className="h-8 w-8 p-2 border bg-white text-center text-xs outline-none" type="number">{produit.quantity}</label>
                 <span onClick={() => changeQuantity(produit.id,produit.quantity + 1)} className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"> + </span>
               </div>
               <div className="flex items-center justify-end gap-2 ml-0">
                 <svg onClick={() => removeCart(produit.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                 </svg>
               </div>
             </div>
           </div>
           </div>
         ))}
         
       </div>
       <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
         <div className="mb-2 flex justify-between">
           <p className="text-gray-700">Sous-total</p>
           <p className="text-gray-700">{totalPrice} TND</p>
         </div>
         <div className="flex justify-between">
           <p className="text-gray-700">Livraison</p>
           <p className="text-gray-700">0 TND</p>
         </div>
         <hr className="my-4" />
         <div className="flex justify-between">
           <p className="text-lg font-bold">Total:</p>
           <div className="">
             <p className="mb-1 text-lg font-bold">{totalPrice}TND</p>
           </div>
         </div>
         <Link to="/Validation" className="mt-6 w-full px-6 rounded-md block text-center bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">CONTINUE</Link>
       </div>
     </div>
    ) : (
      <div className="sm:text-[40px] xs:text-[30px] text-[20px] text-center">Panier est Vide</div>
    )}
  </div>
  )
}

export default Panier