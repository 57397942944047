import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { GiHamburgerMenu } from "react-icons/gi";
import Cart from './Cart';
import { useNavigate } from 'react-router-dom';
import { CiSearch } from 'react-icons/ci';
import { NavLink } from 'react-router-dom';
import { IoSearch } from 'react-icons/io5';
import { FiShoppingCart } from 'react-icons/fi';
import { HiXMark } from 'react-icons/hi2';
import { GrPhone } from 'react-icons/gr';
import { motion } from 'framer-motion';
import { useDisclosure } from '../contexts/DisclosureContext';

export default function Navbar() {
  const [isSearch, setisSearch] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const {setOpen } = useDisclosure();
  const [Query, setQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const setSearch = () => {
     setisSearch(!isSearch);
     setOpen(false)
  }
  const handleSubmit = () => {
    setisSearch(false)
    navigate(`/search/${Query}`);
  }
  if (isSearch) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'auto'; // Reset to auto or initial value
  }

  return (
    <Disclosure as="nav" className={isFixed ? 'fixed top-0 left-0 w-full z-20 bg-white shadow-lg' : ''}>
      <Cart/>
      <div className={`absolute w-screen h-screen ${isSearch ? 'z-50 bg-black top-0 left-0' : '-top-[150%] -left-[150%] -z-50 bg-transparent'} bg-opacity-50`}>
        <div className="w-full h-full relative">
          {isSearch && (
            <motion.div
              initial={{ y: -300, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="w-screen h-[150px] absolute top-0 left-0 bg-white"
            >
              <div className="flex w-full items-center justify-end p-3">
                <div onClick={setSearch} className="p-1 rounded-full cursor-pointer hover:bg-gray-200">
                  <HiXMark className="md:text-[30px] text-[20px]" />
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <div className="relative w-[80%]">
                  <input
                    type="text"
                    placeholder="Cherchez de Produits.."
                    className="p-2 border-b border-blue-400 focus:outline-none md:text-[12px] w-full"
                    value={Query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <button onClick={handleSubmit} className="absolute right-2 top-2 cursor-pointer">
                    <CiSearch className="text-[20px]" />
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-8 lg:px-10 py-3">
        <div className="relative flex h-16 items-center justify-between">
          <div className="inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <GiHamburgerMenu aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden text-black" />
              <HiXMark aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block text-black" />
            </DisclosureButton>
          </div>
          <div className="flex items-center justify-center sm:justify-start">
            <Link to="/" className="flex flex-shrink-0 items-center">
              <img alt="Logo" src="/logo.png" className="h-[60px] w-auto" />
            </Link>
          </div>
          <div className="hidden items-center sm:ml-6 sm:flex">
            <div className="flex space-x-4">
              <NavLink to="/" activeclassName="active" className="Linky text-[18px]">ACCEUIL</NavLink>
              <NavLink to="/Boutique" activeclassName="active" className="Linky text-[18px]">BOUTIQUE</NavLink>
              <NavLink to="/brands" activeclassName="active" className="Linky text-[18px]">BRANDS</NavLink>
              <NavLink to="/calories" activeclassName="active" className="Linky text-[18px]">CALORIES</NavLink>
              <NavLink to="/contact" activeclassName="active" className="Linky text-[18px]">CONTACT</NavLink>
            </div>
          </div>
          <div className="inset-y-0 right-0 justify-center flex items-center pr-2 sm:inset-auto sm:ml-6 sm:space-x-3 sm:pr-0">
            <button
              type="button"
              className="relative rounded-full p-1 text-black focus:outline-none"
              onClick={() => setisSearch(true)}
            >
              <span className="absolute " />
              <span className="sr-only">Search</span>
              <IoSearch className="sm:h-6 sm:w-6 w-[17px] h-[17px]" />
            </button>
            <Link
              to="/contact"
              className="relative rounded-full p-1 text-black focus:outline-none "
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Contact</span>
              <GrPhone className="sm:h-6 sm:w-6 w-[17px] h-[17px]" />
            </Link>
            <button
              type="button"
              className="relative rounded-full p-1 text-black focus:outline-none "
              onClick={() => setOpen(true)}
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Open user menu</span>
              <FiShoppingCart className="sm:h-6 sm:w-6 w-[17px] h-[17px]" />
            </button>
          </div>
        </div>
      </div>
      <DisclosurePanel className={`sm:hidden w-screen bg-opacity-40 absolute z-50 top-0 h-screen bg-black`}>
        <div className={`space-y-1 left-0 w-[200px] ease-in-out pb-3 pt-2 flex flex-col bg-white px-3 duration-300 absolute top-0 h-full`}>
          <div className="flex w-full justify-between p-3 border-b">
            <h1 className="font-bold text-[18px]">Menu</h1>
            <DisclosureButton>
              <HiXMark aria-hidden="true" className="h-6 w-6 text-black" />
            </DisclosureButton>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center justify-center p-2"><NavLink to="/" className="Linky text-[18px]">ACCEUIL</NavLink></div>
            <div className="flex items-center justify-center p-2"><NavLink to="/boutique" className="Linky text-[18px]">BOUTIQUE</NavLink></div>
            <div className="flex items-center justify-center p-2"><NavLink to="/brands" className="Linky text-[18px]">BRANDS</NavLink></div>
            <div className="flex items-center justify-center p-2"><NavLink to="/calories" className="Linky text-[18px]">CALORIES</NavLink></div>
            <div className="flex items-center justify-center p-2"><NavLink to="/contact" className="Linky text-[18px]">CONTACT</NavLink></div>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
