import React from 'react'
import { GiHamburgerMenu } from "react-icons/gi";

function AdminNavbar({setIsOpen}) {
  return (
    <div className="w-full p-4 lg:hidden flex bg-neutral-800">
        <div className="flex w-full justify-between items-center">
            <h1 className="xmd:text-[30px] text-white text-[20px]">ADMIN</h1>
            <div className="p-2" onClick={() => setIsOpen(true)}>
                <GiHamburgerMenu className="text-[17px] text-white"/>
            </div>
        </div>
    </div>
  )
}

export default AdminNavbar