import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useMediaQuery } from 'react-responsive';
import { IoArrowForwardSharp } from "react-icons/io5";
import { IoArrowBackSharp } from "react-icons/io5";


const useBreakpoints = () => {
    const xl = useMediaQuery({ minWidth: 1024 });
    const xxmd = useMediaQuery({ minWidth: 800, maxWidth: 1023 });
    const md = useMediaQuery({ minWidth: 500, maxWidth: 799 });
    const sm = useMediaQuery({ minWidth: 300, maxWidth: 499 });
    const xs = useMediaQuery({ maxWidth: 299 });

    const getSlidesToShow = () => {
        if (xl) return 5;
        if (xxmd) return 4;
        if (md) return 3;
        if (sm) return 2;
        if (xs) return 1;
        return 5; // Default to 5 if no condition matches
    };

    return getSlidesToShow;
};

const products = [
    { id: 1, name: "MASS GAINER", imgSrc: "/Categories/imageOne.png" },
    { id: 2, name: "WHEY PROTEINE", imgSrc: "/Categories/imageTwo.png" },
    { id: 3, name: "ACIDES AMINES", imgSrc: "/Categories/imageThree.png" },
    { id: 4, name: "PREWORKOUT", imgSrc: "/Categories/imageFour.png" },
    { id: 5, name: "CREATINE", imgSrc: "/Categories/imageFive.png" },
    { id: 6, name: "MULTI VITAMINE", imgSrc: "/Categories/imageSix.png" },
    { id: 7, name: "BRULURES DE GRAISSE", imgSrc: "/Categories/imageSeven.png" },
    { id: 8, name: "ACCESSOIRES", imgSrc: "/Categories/imageEight.png" },
    { id: 9, name: "SNACK", imgSrc: "/Categories/imageNine.png" },
];

const CardSlider = () => {
    const slidesToShow = useBreakpoints();

    return (
        <div className="w-full sm:px-0 px-6 relative">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={slidesToShow()}
                spaceBetween={10}
                loop={true}
                navigation={{
                    nextEl: '.swiper-button-next-categorie',
                    prevEl: '.swiper-button-prev-categorie',
                }}
            >
                {products.map(product => (
                  <SwiperSlide key={product.id} className="bg-gray-200 sm:w-[197px] w-[100px] h-[100px] sm:h-[126px] rounded-xl">
                    <div className="p-2 w-full h-full">
                        <div className="h-full w-full flex items-center justify-center">
                            <div className="flex flex-col gap-2">
                                <div className="w-full flex items-center justify-center">
                                    <img src={product.imgSrc} alt="product" className="sm:w-[50px] w-[30px] h-[30px] sm:h-[50px]" />
                                </div>
                                <span className="text-[10px] sm:text-[14px] text-center truncate-multiline">{product.name}</span>
                            </div>
                        </div>
                    </div>
                   </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-button-prev-categorie absolute top-1/2 left-0 transform -translate-y-1/2 sm:-translate-x-2/3 z-10 cursor-pointer">
                <span className="inline-block p-2 sm:text-[20px] text-[13px] text-white bg-gray-800  rounded-full"><IoArrowBackSharp /></span>
            </div>
            <div className="swiper-button-next-categorie absolute top-1/2 right-0 transform -translate-y-1/2 sm:translate-x-2/3 z-10 cursor-pointer">
                <span className="inline-block p-2 sm:text-[20px] text-[13px] text-white bg-gray-800 rounded-full"><IoArrowForwardSharp /></span>
            </div>
        </div>
    );
};

export default CardSlider;
