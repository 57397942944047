import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import { toast } from 'sonner'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { app } from '../../firebaseConfig'
import axios from "axios"

function AddProduct() {

  const [product, setProduct] = useState({
    brand: '',
    categorie: '',
    description: '',
    ensolde: false,
    image: null,
    instock: true,
    prix: 0,
    solde: { oldprix: 0 },
    titre: '',
  });
  const [imageLoading, setimageLoading] = useState(false);
  const [Error, setError] = useState("");
  const [isOverview, setisOverview] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const validateForm = () => {
    if (!product.brand) {
      setError("Brand is required");
      return false;
    }
    if (!product.categorie) {
      setError("Category is required");
      return false;
    }
    if (!product.description) {
      setError("Description is required");
      return false;
    }
    if (!product.image) {
      setError("Image is required");
      return false;
    }
    if (product.prix <= 0) { // Check for null and non-positive values
      setError("Price must be greater than 0");
      return false;
    }
    if (!product.titre) {
      setError("Title is required");
      return false;
    }
    setError(''); // Clear error if all validations pass
    return true;
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'ensolde' || name === 'instock') {
        setProduct({ ...product, [name]: value === 'true' });
    } else if (name === 'image') {
        const file = files[0];
        setProduct((prev) => ({ ...prev, image: file }));
        const ImageUrl = URL.createObjectURL(file); // Create a URL from the selected file
        setImageUrl(ImageUrl); // Set the imageUrl state to display the image
    } else if (name === 'prix') {
        setProduct({ ...product, prix: parseFloat(value) || null });
    } else {
        setProduct({ ...product, [name]: value });
    }
  };
  const handleSet = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setisOverview(true)
    }
  };
  const handleSubmitProduct = async (e) => {
    e.preventDefault();
    setimageLoading(true);

    try {
        // Initialize Firebase Storage and create a reference for the image
        const storage = getStorage(app);
        const storageRef = ref(storage, `produits/${product.image.name}`);

        // Upload the image
        const uploadTask = uploadBytesResumable(storageRef, product.image);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Optionally, you can handle progress here
          },
          (error) => {
            console.error('Upload failed:', error);
            setimageLoading(false); // Stop loading if there's an error
          },
          async () => {
            // Once the upload is complete, get the download URL
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setImageUrl(downloadURL);

            // Prepare the product data including the image URL
            const productData = {
                ...product,
                image: downloadURL, 
            };
            console.log(productData)
            try {
                // Send the data using Axios
                const response = await axios.post(`/.netlify/functions/addProduct`, productData);
                if (response.data.Status === "Success") {
                    setimageLoading(false);
                    setisOverview(false);
                    toast.success('Product created successfully');
                }
            } catch (error) {
                console.error('Error submitting product:', error);
                toast.error('Error submitting product');
            } 
          }
        );
    } catch (error) {
        console.error('Error during product submission:', error);
        setimageLoading(false);
        toast.error('Error during product submission');
    }
  };

  const calcPour = (oldPrice, newPrice) => {
    const discount = oldPrice - newPrice;
    const discountPercentage = (discount / oldPrice) * 100;
  
    return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
  }
  return (
    <section className="flex flex-grow">
       <div className="w-full flex flex-col p-4">
          {isOverview && (
            <div className="absolute top-0 left-0 w-screen h-full bg-white p-2">
                <div className="relative flex gap-4 items-center justify-center w-full h-full flex-col">
                            <div onClick={() => !imageLoading && setisOverview(false)} className="cursor-pointer absolute top-0 right-0 p-2 text-[30px] hover:bg-gray-200 rounded-full">
                                <IoClose />
                            </div>
                            <div className="text-[30px] mb-4">OVERVIEW DE PRODUIT</div>
                            <div  className="min-h-[350px] w-[250px] relative overflow-y-hidden bg-gray-200 rounded-xl shadow group cursor-pointer">
                                {product.ensolde && (<div className="absolute top-3 left-3 bg-red-500 diagonal-rectangle rounded-sm text-[12px] text-white p-1">{calcPour(product.solde.oldprix,product.prix)}% OFF</div>)}
                                <Link to={`/product/${product.titre}`} className="flex flex-col">
                                   <div className="flex w-full h-[250px]">
                                   {imageUrl && (
                                         <img
                                           src={imageUrl}
                                           alt="Product"
                                           className="rounded-t-xl w-full h-full"
                                          />
                                    )}                         
                                   </div>
                                   <div className="flex flex-col w-full p-4 gap-[5px]">
                                         <h1 className="truncate-multiline hover:text-red-600 cursor-pointer uppercase">{product.titre}</h1>
                                         <p className="hover:text-red-600 underline cursor-pointer text-[14px] text-gray-400 uppercase">{product.categorie}</p>
                                         <div className="flex gap-2 items-center">
                                            {product.ensolde && (
                                               <p className="line-through text-gray-400 text-[14px]">{product.solde.oldprix.toFixed(3)}TND</p>
                                            )}
                                            <p className="text-red-500 text-[17px]">{product.prix.toFixed(3)}TND</p>
                                         </div>
                                   </div>
                                </Link>
                            </div>  
                            <div className="gap-4 flex">
                               <button onClick={() => !imageLoading && setisOverview(false)} className="uppercase px-8 py-1 text-[15px] bg-white text-black border border-black rounded-xl">annuler</button>
                               {imageLoading ? (<button className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl"><svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></button>) : (<button onClick={handleSubmitProduct} className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl">créer</button>)}
                            </div>
                </div>
            </div>
          )}
          <div className="w-full flex items-center px-4 justify-start">
              <h1 className="Linky md:text-[50px] text-[25px]">Ajouter Un Produit</h1>
          </div>
          <div className="w-full mt-4">
           <form onSubmit={handleSet} className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-md">
             {Error && <p className="text-red-500 mb-4">{Error}</p>}
             <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Brand:</label>
            <select
              name="brand"
              value={product.brand}
             onChange={handleChange}
             className="w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="" disabled>Select a brand</option>
              <option value="Ultimate Nutrition">Ultimate Nutrition</option>
              <option value="Optimum Nutrition">Optimum Nutrition</option>
              <option value="Muscle Tech">Muscle Tech</option>
              <option value="Nutrex">Nutrex</option>
              <option value="Nutrend">Nutrend</option>
              <option value="Biotech USA">Biotech USA</option>
              <option value="Applied Nutrition">Applied Nutrition</option>
              <option value="Olimp">Olimp</option>
              <option value="Animal">Animal</option>
              <option value="Impact Nutrition">Impact Nutrition</option>
              <option value="Great Sport Nutrition">Great Sport Nutrition</option>
              <option value="Hx Nutrition">Hx Nutrition</option>
              <option value="Real Pharm">Real Pharm</option>
            </select>
             </div>

             <div className="mb-4">
           <label className="block text-gray-700 font-bold mb-2">Category:</label>
           <select
             name="categorie"
              value={product.categorie}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="" disabled>Select a category</option>
              <option value="Mass Gainer">Mass Gainer</option>
              <option value="Whey Gainer">Whey Gainer</option>
              <option value="Acides Aminés">Acides Aminés</option>
              <option value="Preworkout">Preworkout</option>
              <option value="Créatine">Créatine</option>
              <option value="Multi Vitamine">Multi Vitamine</option>
              <option value="Brulures de graisse">Brulures de graisse</option>
              <option value="Accessoires">Accessoires</option>
              <option value="Snack">Snack</option>
           </select>
             </div>

             <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">Description:</label>
               <textarea
                 name="description"
                 value={product.description}
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               />
             </div>
             <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">Image:</label>
               <input
                 type="file"
                 name="image"
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               />
             </div>
             <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">In Stock:</label>
               <select
                 name="instock"
                 value={product.instock ? 'true' : 'false'}
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               >
                 <option value="true">true</option>
                 <option value="false">false</option>
               </select>
             </div>
             <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">Prix:</label>
               <input
                 type="number"
                 name="prix"
                 value={product.prix}
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               />
             </div>
             <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">Titre:</label>
               <input
                 type="text"
                 name="titre"
                 value={product.titre}
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               />
             </div>
             <button type="submit" className="w-full mt-4 bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-200">
                Create Product
             </button>
            </form>
          </div>
       </div>
    </section>
  )
}

export default AddProduct