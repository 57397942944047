//SearchResults.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useCart } from '../contexts/PanierContext';
import {Link} from 'react-router-dom'
import Loading from '../components/Loading';
import { useDisclosure } from '../contexts/DisclosureContext';

const SearchProducts = () => {
    const { query } = useParams();
    const [results, setResults] = useState([]);
    const [isHovered, setIsHovered] = useState(false)
    const { addCart } = useCart();
    const [isLoading, setisLoading] = useState(true);
    const { setOpen } = useDisclosure();
    const handleAddToCart = (produit) => {
        const newProduit = {
          ...produit,        // Spread all properties from the produit object
          quantity: 1,
          id: Math.random().toString(36).substr(2, 9), // Generate a random ID     
          livraison:0
        };
      
        addCart(newProduit); // Add the newProduit to the cart
        setOpen(true)
    };
    const calcPour = (oldPrice, newPrice) => {
        const discount = oldPrice - newPrice;
        const discountPercentage = (discount / oldPrice) * 100;
      
        return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
    }
    useEffect(() => {
        if (query) {
            const fetchResults = async () => {
                try {
                    const response = await axios.get(`/.netlify/functions/SearchProducts`, { params: { query } });
                    if(response.data.Status === "Success"){
                        setResults(response.data.Data);
                    } else {
                        setResults([])
                    }
                    setisLoading(false);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            };
            fetchResults();
        }
    }, [query]);


    if(isLoading){
        return <Loading/>
      }
    return (
    <section className="relative z-10 overflow-hidden bg-gray-100 py-20 dark:bg-dark lg:pb-[120px] lg:pt-[60px]">
      <div className="flex flex-col">
        <h1 className="mb-8 sm:mx-[120px] mx-4 sm:text-[50px] xs:text-[30px] text-[20px] text-center font-bold Linky">
          Vous Avez Cherché "{query}"
        </h1>
        <div className="flex items-center justify-start w-full lg:px-17 px-2">
           <h1 className="lg:text-[35px] sm:text-[30px] text-[23px] Linky uppercase">résultats:</h1>
        </div>
        <div className="w-full pb-12 pt-6 xs:px-4 sm:px-6 flex flex-wrap justify-center gap-6">
              {results.length !== 0 ? (
                        <div className="lg:px-17 md:px-2 sm:px-10 xmd:px-2 xxmd:px-20 px-4 gap-4 grid  lg:grid-cols-4 md:grid-cols-3 xmd:grid-cols-2 grid-cols-1">
                         {results.map((produit) => (
                          <div onMouseEnter={() => setIsHovered(true)}  onMouseLeave={() => setIsHovered(false)} className="h-[400px]  relative overflow-y-hidden bg-gray-200 rounded-xl shadow group cursor-pointer">
                               {isHovered && ( <motion.div  initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }}  transition={{ duration: 0.2 }}className="text-white hover:bg-blue-500 group-hover:block absolute w-full left-0 h-[50px] bg-black bottom-0 hidden">
                                   <div onClick={() => handleAddToCart(produit)} className="w-full h-full flex items-center justify-center">
                                      <p>AJOUTER AU PANIER</p>
                                   </div>
                               </motion.div>)}
                                {produit.ensolde && (<div className="absolute top-3 left-3 bg-red-500 diagonal-rectangle rounded-sm text-[12px] text-white p-1">{calcPour(produit.solde.oldprix,produit.prix)}% OFF</div>)}
                                <Link to={`/produit/${produit.titre}`} className="flex flex-col">
                                   <div className="flex w-full h-[250px]">
                                     <img src={produit.image} alt="l'image de produit" className="rounded-t-xl w-full h-full"/>
                                   </div>
                                   <div className="flex flex-col w-full p-4 gap-[5px]">
                                         <h1 className="truncate-multiline hover:text-red-600 cursor-pointer uppercase">{produit.titre}</h1>
                                         <p className="hover:text-red-600 underline cursor-pointer text-[14px] text-gray-400 uppercase">{produit.categorie}</p>
                                         <div className="flex gap-2 items-center">
                                            {produit.ensolde && (
                                               <p className="line-through text-gray-400 text-[14px]">{produit.solde.oldprix.toFixed(3)}TND</p>
                                            )}
                                            <p className="text-red-500 text-[17px]">{produit.prix.toFixed(3)}TND</p>
                                         </div>
                                   </div>
                                </Link>
                           </div>      
                          ))} 
                          </div>
              ) : (
                 <div className="text-center text-[14px]">No Résultat</div>
              )}            
        </div>
      </div>
    </section>
    );
};

export default SearchProducts;
