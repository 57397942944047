import React from 'react'
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-gray-900">
    <div className="container sm:px-6 px-2 sm:py-8 py-4 mx-auto">
        <div className="flex gap-2 sm:space-x-20 sm:items-start items-center sm:w-auto w-full sm:flex-row flex-col justify-center">
            <Link to="/" className="text-gray-900 sm:m-0 mb-6">
                <img className="sm:h-[130px] h-[80px] sm:w-[130px] w-[80px]" src="/logo.png" alt="logo" />
            </Link>
            <div className="flex sm:w-auto w-full space-y-10 sm:space-x-10 sm:flex-row flex-col items-center sm:items-start justify-center">
                <div>
                      <div className="text-xs font-medium text-gray-400 uppercase">
                          USEFUL LINKS
                      </div>

                      <Link to="/" className="block mt-5 text-sm font-medium text-gray-500 duration-700 hover:text-md hover:text-gray-400  hover:underline">
                          ACCEUIL
                      </Link>
                      <Link to="/brands" className="block mt-3 text-sm font-medium text-gray-500 duration-700 hover:text-md hover:text-gray-400  hover:underline">
                          BRANDS
                      </Link>
                      <Link to="/calories" className="block mt-3 text-sm font-medium text-gray-500 duration-700 hover:text-md hover:text-gray-400  hover:underline">
                          CALORIES
                      </Link>
                      <Link to="/boutique" className="block mt-3 text-sm font-medium text-gray-500 duration-700 hover:text-md hover:text-gray-400  hover:underline">
                          BOUTIQUE
                      </Link>                    
                </div>
                <div className="sm:ml-0 ml-4">
                    <p className="font-semibold text-gray-400 sm:text-md text-sm">CONTACTEZ NOUS</p>

                    <div className="flex flex-col items-start mt-5 space-y-2">
                        <button className="text-gray-500 transition-colors duration-300  hover:underline text-sm hover:text-gray-400">57 044 501 / 57 044 510</button>
                        <button className="text-gray-500 transition-colors duration-300  hover:underline text-sm hover:text-gray-400">atefdhrifi20@gmail.com</button>
                    </div>
                </div>
            </div>
        </div>
        <hr className="my-6 border-gray-200" />
        <div className="flex flex-col items-center sm:flex-row sm:justify-center">
            <p className="sm:text-sm text-xs text-gray-500">Globale Protéine © All Rights Reserved.</p>
        </div>
    </div>
</footer>
  )
}

export default Footer