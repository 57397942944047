import React, { createContext, useContext, useEffect, useState } from "react";

const initCart = {
  Cart: [],
};

export const PanierContext = createContext();

const getInitialState = () => {
  const Cart = localStorage.getItem("Cart");
  return Cart ? JSON.parse(Cart) : initCart;
};

const PanierContextProvider = (props) => {
  const [Cart, setCart] = useState(getInitialState);
  const [totalPrice, setTotalPrice] = useState(0);

  // Moved calculateTotalPrice inside the provider to access Cart and setTotalPrice
  const calculateTotalPrice = (Cart) => {
    const total = Cart.Cart.reduce((acc, item) => {
      const itemPrice = item.prix;
      return acc + itemPrice * item.quantity;
    }, 0);

    // Format the total price to always have three decimal places
    const formattedTotal = total.toFixed(3);

    setTotalPrice(formattedTotal);
  };

  useEffect(() => {
    localStorage.setItem("Cart", JSON.stringify(Cart));
    calculateTotalPrice(Cart); // Call the function with Cart whenever it changes
  }, [Cart]);

  const addCart = (product) => {
    setCart((prev) => {
      const updatedCart = {
        ...prev,
        Cart: [...prev.Cart, product],
      };
      localStorage.setItem("Cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const removeCart = (CartId) =>
    setCart((prev) => {
      const updatedCart = {
        ...prev,
        Cart: prev.Cart.filter((p) => p.id !== CartId),
      };
      localStorage.setItem("Cart", JSON.stringify(updatedCart));
      return updatedCart;
    });

  const changeQuantity = (CartId, newQuantity) => {
    setCart((prev) => {
      const updatedCart = {
        ...prev,
        Cart: prev.Cart.map((item) =>
          item.id === CartId ? { ...item, quantity: newQuantity } : item
        ),
      };
      localStorage.setItem("Cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  return (
    <PanierContext.Provider value={{ addCart, removeCart, changeQuantity, totalPrice, ...Cart }}>
      {props.children}
    </PanierContext.Provider>
  );
};

export const useCart = () => useContext(PanierContext);

export default PanierContextProvider;
