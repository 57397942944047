
import { FaShoppingCart } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import { FaShippingFast } from 'react-icons/fa';

export default function Features() {
  return (
    <div className="w-full flex items-center justify-center xmd:bg-blue-300">
      <div className="bg-blue-300 xmd:flex hidden">
        <div className="lg:mx-12 px-6 lg:px-0 flex items-center justify-center">
          <dl className="flex py-6 gap-6 xmd:flex-row flex-col items-center justify-between">
              <div className="md:min-w-[250px] min-w-[150px]  flex items-center justify-center gap-2">
                  <dt className="md:text-[20px] xmd:text-[10px] text-[12px] text-white"><FaShoppingCart /></dt>
                  <dt className="md:text-[16px] xmd:text-[10px] text-[12px] font-extrabold leading-7 text-white">
                     Large Gamme de Produits
                  </dt>
              </div>
              <div className="md:min-w-[250px] min-w-[150px] flex items-center justify-center gap-2 w-full xmd:bg-transparent bg-white">
                  <dt className="md:text-[20px] xmd:text-[10px] text-[12px] text-blue-300 xmd:text-white"><MdVerified /></dt>
                  <dt className="md:text-[16px] xmd:text-[10px] text-[12px] font-extrabold leading-7 text-blue-300 xmd:text-white">
                      Assurance Qualité
                  </dt>
              </div>
              <div className="md:min-w-[250px] min-w-[150px] flex items-center justify-center gap-2">
                  <dt className="md:text-[20px] xmd:text-[10px] text-[12px] text-white"><FaShippingFast /></dt>
                  <dt className="md:text-[16px] xmd:text-[10px] text-[12px] font-extrabold leading-7 text-white">
                    Livraison Rapide
                  </dt>
              </div>
          </dl>
        </div>
      </div>
      <div className="mx-auto w-full xmd:hidden flex">
        <div className="px-6 flex items-center justify-center w-full">
          <dl className="flex py-6 gap-6 xmd:flex-row flex-col items-center justify-between">
              <div className="min-w-[150px]  flex items-center justify-center gap-2">
                  <dt className="sm:text-[18px] text-[14px] text-blue-300"><FaShoppingCart /></dt>
                  <dt className="sm:text-[18px] text-[14px] font-extrabold leading-7 text-blue-300">
                     Large Gamme de Produits
                  </dt>
              </div>
              <hr className="border-blue-300 w-full" />
              <div className="min-w-[150px] flex items-center justify-center gap-2 w-full xmd:bg-transparent bg-white">
                  <dt className="sm:text-[18px] text-[14px] text-blue-300 "><MdVerified /></dt>
                  <dt className="sm:text-[18px] text-[14px] font-extrabold leading-7 text-blue-300">
                      Assurance Qualité
                  </dt>
              </div>
              <hr className="border-blue-300 w-full" />
              <div className="min-w-[150px] flex items-center justify-center gap-2">
                  <dt className="sm:text-[18px] text-[14px] text-blue-300"><FaShippingFast /></dt>
                  <dt className="sm:text-[18px] text-[14px] font-extrabold leading-7 text-blue-300">
                    Livraison Rapide
                  </dt>
              </div>
          </dl>
        </div>
      </div>
    </div>
  )
}
