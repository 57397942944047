import {React} from 'react'
import ImageSlider from '../components/ImageSlider';
import Features from '../components/Features';
import Products from '../components/Products';
import Categories from '../components/Categories';
import Brands from '../components/Brands';

const HomePage = ({EnSoldeProduits,setEnSoldeProduits}) => {
  return (
    <>
      <ImageSlider />
      <Features />
      <Products Produits={EnSoldeProduits} setProduits={setEnSoldeProduits}/>
      <Categories />
      <div className="w-full py-4 flex items-center justify-center">
         <img src="/Images/ImageThree.png" alt="bg" className="md:w-[70%] xmd:w-[90%] w-full"/> 
      </div>
      <Brands />
    </>
  );
};

export default HomePage;
