import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { useCart } from '../contexts/PanierContext';
import {Link} from 'react-router-dom'
import { useDisclosure } from '../contexts/DisclosureContext';

function Products({Produits}) {
  const [isHovered, setIsHovered] = useState(false)
  const { addCart } = useCart();
  const { setOpen } = useDisclosure();
  const handleAddToCart = (produit) => {
    const newProduit = {
      ...produit,        // Spread all properties from the produit object
      quantity: 1,
      id: Math.random().toString(36).substr(2, 9), // Generate a random ID     
      livraison:0
    };
  
    addCart(newProduit); // Add the newProduit to the cart
    setOpen(true);       // Optionally open a modal or give feedback
  };
  const calcPour = (oldPrice, newPrice) => {
    const discount = oldPrice - newPrice;
    const discountPercentage = (discount / oldPrice) * 100;
  
    return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
  }
  return (
    <div className="w-screen p-3">
         <div className="flex w-full flex-col gap-6">
             <div className="flex items-center justify-center w-full">
                  <h1 className="lg:text-[50px] sm:text-[40px] text-[30px] Linky">NOS PRODUITS</h1>
             </div>
             <div className="w-full">
                 <div className="flex flex-col w-full gap-4">
                   <div className="flex items-center justify-start w-full lg:px-17 px-2">
                     <h1 className="lg:text-[35px] sm:text-[30px] text-[23px] Linky">SPECIAL OFFER :</h1>
                   </div>
                       {Produits.length !== 0 && (
                        <div className="lg:px-17 md:px-2 sm:px-10 xmd:px-2 xxmd:px-20 px-4 gap-4 grid  lg:grid-cols-4 md:grid-cols-3 xmd:grid-cols-2 grid-cols-1">
                         {Produits.map((produit,index) => (
                          <div key={index} onMouseEnter={() => setIsHovered(true)}  onMouseLeave={() => setIsHovered(false)} className="h-[400px]  relative overflow-y-hidden bg-gray-200 rounded-xl shadow group cursor-pointer">
                               {isHovered && ( <motion.div  initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }}  transition={{ duration: 0.2 }}className="text-white hover:bg-blue-500 group-hover:block absolute w-full left-0 h-[50px] bg-black bottom-0 hidden">
                                   <div onClick={() => handleAddToCart(produit)} className="w-full h-full flex items-center justify-center">
                                      <p>AJOUTER AU PANIER</p>
                                   </div>
                               </motion.div>)}
                                {produit.ensolde && (<div className="absolute top-3 left-3 bg-red-500 diagonal-rectangle rounded-sm text-[12px] text-white p-1">{calcPour(produit.solde.oldprix,produit.prix)}% OFF</div>)}
                                <Link to={`/produit/${produit.titre}`} className="flex flex-col">
                                   <div className="flex w-full h-[250px]">
                                     <img src={produit.image} alt="l'image de produit" className="rounded-t-xl w-full h-full"/>
                                   </div>
                                   <div className="flex flex-col w-full p-4 gap-[5px]">
                                         <h1 className="truncate-multiline hover:text-red-600 cursor-pointer uppercase">{produit.titre}</h1>
                                         <p className="hover:text-red-600 underline cursor-pointer text-[14px] text-gray-400 uppercase">{produit.categorie}</p>
                                         <div className="flex gap-2 items-center">
                                            {produit.ensolde && (
                                               <p className="line-through text-gray-400 text-[14px]">{produit.solde.oldprix.toFixed(3)}TND</p>
                                            )}
                                            <p className="text-red-500 text-[17px]">{produit.prix.toFixed(3)}TND</p>
                                         </div>
                                   </div>
                                </Link>
                           </div>      
                          ))} 
                          </div>
                       )}                                    
                 </div>
             </div>
         </div>
    </div>
  )
}

export default Products